import _ from "lodash";
import {Common, commonDataImp} from "./Common";
import * as yup from "yup";
import {Child, childDataImpl} from "./Child";
import {BASEURL_RESOURCES} from "../../config/AppUrl";
import {Employee} from "./Employee";

export const ALBUMPICTURE_CONSTANT = {
    convertToFrontEnd: (logic:any, item:AlbumPicture) => {
        const itemInit = _.cloneDeep(albumPictureDataImpl);
        item =  {...itemInit, ...item} ;

        item.childForm = _.find(logic.childrenRef.current, {id:item?.enfantId});
        item.lastUpdate = item.common?.miseajour;
        item.img = `${BASEURL_RESOURCES}/${item.uri}`;
        const employee:Employee = _.find(logic.employeesRef.current, {id:item.employeeId});
        if(_.isObject(employee)){
            item.author = `${employee.person?.prenom} ${employee.person?.nom}`;
        }
        // @ts-ignore
       return item ;
    },
    convertToBackEnd: (logic:any, item:AlbumPicture) => item,
};

export  const albumPictureDataImpl: AlbumPicture = {
    id: null,
    enfantId: 0,
    employeeId: 0,
    nom: '',
    uri:'',
    img:'',
    author:'',
    usersIdDeleted: '',
    lastUpdate:0,
    childForm: _.cloneDeep(childDataImpl),
    isOwnerForm: true,
    common: _.cloneDeep(commonDataImp)
}

export const albumPictureSchema = yup.object().shape({
    id: yup.number().nullable(),
    enfantId: yup.number(),
    employeeId: yup.number(),
    nom: yup.string(),
    uri: yup.string(),
    img: yup.string(),
    author: yup.string(),
    lastUpdate: yup.number(),
    usersIdDeleted: yup.string().default(''),
    isOwnerForm: yup.boolean().default(true),
    childForm:yup.mixed<Child>(),
    common: yup.mixed<Common>()
});

export type AlbumPicture = yup.InferType<typeof albumPictureSchema>;