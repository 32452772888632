import _ from "lodash";
import {Common, commonDataImp} from "./Common";
import * as yup from "yup";
import moment from "moment";
import React from "react";
import {isColumnVisible, isPositiveValue} from "../../helpers/helpers";
import messages from "../../page/messages/general/messages";
import {ScolariteGroup} from "./ScolariteGroup";
import {MyBadge} from "../../components/ui/badge/MyBadge";

export const ECHEANCIERGROUPING_CONSTANT = {
    convertScolariteGroupToFrontEnd: (logic:any, item:ScolariteGroup) : EcheancierGrouping => {
        // @ts-ignore
        const itemInit = _.cloneDeep(echeancierGroupingDataImpl);
        const data = {...itemInit, ...item} ;

        // @ts-ignore
        data.liredateForm           = ``;
        // @ts-ignore
        data.rappeldateForm         = isPositiveValue(data.rappeldate) ? moment(data.rappeldate) : null;

        data.scolariteGroupId           = item?.id ?? 0;
        data.scolariteAnneeId           = item.scolariteAnneeId ?? 0;
        data.scolariteTypeId            = item.scolariteTypeForm?.id ?? 0;
        data.groupeSectionId            = item.groupesectionForm?.id ?? 0;
        data.scolariteGroupProfilId     = item.profilsId ;
        data.scolariteTypeNomForm       = item.scolariteTypeForm?.nom ;
        data.groupNomForm               = item?.groupesectionForm?.nom ;
        data.scolariteGroupMontant      = item?.montant ;

        return data ;
    },
    convertToBackEnd: (logic:any, item:EcheancierGrouping) => {
        item.ladate          = item.ladateForm?.valueOf();
        // @ts-ignore
        item.rappeldate      = _.isObject(item.rappeldateForm) ? item.rappeldateForm?.valueOf() : null;

        return item ;
    },
};
export  const echeancierGroupingDataImpl: EcheancierGrouping = {
    id: null,
    ladate: moment().startOf('day').valueOf(),
    nom: ``,
    code: ``,
    scolariteTypeTag: null,
    scolariteGroupId: 0,
    scolariteGroupProfilId: 0,
    groupeSectionId: 0,
    scolariteAnneeId:0,
    scolariteTypeId:0,
    groupesectionIds:[],
    IdsToDelete: [],
    montant: 0,
    scolariteGroupMontant: 0,
    obligatoire: false,
    echeance: false,
    rappeldate: null,
    ladateForm: moment(),
    scolariteTypeNomForm: '',
    groupNomForm: '',
    liredateForm: '',
    sumEcheancierDetail: 0,
    rappeldateForm: null,
    echeanciersDetailsForm:[],
    typeModeForm: 'EcheancierGrouping',
    common: _.cloneDeep(commonDataImp),
}

export const echeancierGroupingSchema = yup.object().shape({
    id: yup.number().nullable().notRequired(),
    nom: yup.string().required().notRequired(),
    code: yup.string().nullable().notRequired(),
    ladate: yup.number().nullable().notRequired(),
    scolariteTypeTag: yup.string().notRequired(),
    IdsToDelete: yup.array().of(yup.number()).nonNullable().default([]).notRequired(),
    groupesectionIds: yup.array().of(yup.number()).nonNullable().default([]).notRequired(),
    scolariteGroupProfilId: yup.number().notRequired(),
    scolariteGroupId: yup.number().notRequired(),
    groupeSectionId: yup.number().notRequired(),
    scolariteAnneeId: yup.number().notRequired(),
    scolariteTypeId: yup.number().notRequired(),
    montant: yup.number().default(0).notRequired(),
    sumEcheancierDetail: yup.number().default(0).notRequired(),
    scolariteGroupMontant: yup.number().default(0).notRequired(),
    obligatoire: yup.boolean().default(false).notRequired(),
    echeance: yup.boolean().default(false).notRequired(),
    rappeldate: yup.number().nullable().notRequired(),
    rappeldateForm: yup.mixed<Object>().nullable().notRequired(),
    ladateForm: yup.mixed<any>().nullable().notRequired(),
    liredateForm: yup.string().nonNullable().notRequired(),
    groupNomForm: yup.string().notRequired(),
    scolariteTypeNomForm: yup.string().notRequired(),
    typeModeForm: yup.string().notRequired().default('EcheancierGrouping'),
    common: yup.mixed<Common>(),

    echeanciersDetailsForm: yup.array().of(yup.mixed<any>()).nonNullable().default([]).notRequired(),
});
export type EcheancierGrouping = yup.InferType<typeof echeancierGroupingSchema>;


export const excelEcheancierGroupingDataMapping = (
    {rows=[], columns=[], intl={}, table={}, prefix=``}:{ rows:any[]; intl:any;  table: any; columns: any[]; prefix: string;}
) => {
    const echeanciers = _.map(rows, (row:any) => {
        let obj:any = {};
        const data = row.original;
        if(isColumnVisible(columns, `${prefix}scolariteTypeNomForm`)){
            obj[`${intl.formatMessage({id:messages.schooling_fees_type})}`] = data.scolariteTypeNomForm;
        }
        if(isColumnVisible(columns, `${prefix}groupNomForm`)){
            obj[`${intl.formatMessage({id:messages.table_h_group})}`] = data.groupNomForm;
        }
        if(isColumnVisible(columns, `${prefix}nom`)){
            obj[`${intl.formatMessage({id:messages.table_h_echeancier_nom})}`] = data.nom;
        }
        if(isColumnVisible(columns, `${prefix}code`)){
            obj[intl.formatMessage({id:messages.header_code})] = data.code;
        }
        if(isColumnVisible(columns, `${prefix}montant`)){
            obj[`${intl.formatMessage({id:messages.table_h_montant})}`] = data.montant;
        }
        if(isColumnVisible(columns, `${prefix}liredateForm`)){
            obj[`${intl.formatMessage({id:messages.date_limite})}`] = data.liredateForm;
        }
        if(isColumnVisible(columns, `${prefix}obligatoire`)){
            obj[`${intl.formatMessage({id:messages.schooling_fees_obligatory})}`] = data.obligatoire;
        }
        return obj;
    });
    return echeanciers;
}

export const echeancierGroupingCustomOptions = {
    columnVisibility : {
       /* 'scolariteTypeNomForm': true,*/
        'code': false,
        'obligatoire': true,
    }
} ;
export const columnsEcheanciersGrouping = (props:any) => {
    const {intl, nomSpace} = props ;
    const infoSpace = nomSpace ?? ``;
    return [
        // @ts-ignore
        /*{
            accessorKey: 'scolariteTypeNomForm',
            filterVariant: 'multi-select',
            header: `${intl.formatMessage({id:messages.schooling_fees_type})}`,
        },*/
        {
            accessorKey: 'groupNomForm',
            filterVariant: 'multi-select',
            size:120,
            header: `${intl.formatMessage({id:messages.table_h_group})}`,
        },
        {
            accessorKey: 'nom',
            size:120,
            header: `${intl.formatMessage({id:messages.table_h_echeancier_nom})}`,
        },
        {
            accessorKey: 'code',
            size:80,
            header: `${intl.formatMessage({id:messages.header_code})}`,
        },
        {
            accessorKey: 'montant',
            header: `${infoSpace} ${intl.formatMessage({id:messages.table_h_montant})}`,
            filterVariant: 'range-slider',
            filterFn: 'betweenInclusive', // default (or between)
            muiFilterSliderProps: {
                //no need to specify min/max/step if using faceted values
                marks: true,
                step: 5_000,
                valueLabelFormat: (value:number) =>
                    Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(value)
            },
            muiTableBodyCellProps: {
                align: 'right',
                fontWeight: 'bold'
            },
            // @ts-ignore
            Cell: ({cell}) => {
                const data = cell.row.original;
                const toWarn = data.typeModeForm === 'EcheancierGrouping' && data.sumEcheancierDetail !== data.montant;
                const style =  toWarn ? {color: 'indianred'} : {} ;
                const ponctuation =  toWarn ? ` !` : `` ;
                return ( <b style={style}>
                        {
                            // @ts-ignore
                            Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(cell.getValue<number>())
                        }
                        {ponctuation}
                    </b>
                );
                // @ts-ignore
                //return <b>{Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(cell.getValue<number>())}</b>
            },
            /*accessorFn: (row:any) => row,
            muiTableBodyCellProps: {
                align: 'right',
                fontWeight: 'bold'
            },
            // @ts-ignore
            Cell: ({cell}) => {
                // @ts-ignore
                const row = cell.getValue<any>();
                const toWarn = row.typeModeForm === 'EcheancierGrouping' && row.sumEcheancierDetail !== row.montant;
                const style =  toWarn ? {color: 'indianred'} : {} ;
                const ponctuation =  toWarn ? ` !` : `` ;
                return ( <b style={style}>
                        {
                            Intl
                                .NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' })
                                .format(row.montant)
                        }
                        {ponctuation}
                    </b>
                );
            },*/
        },
        {
            accessorKey: 'liredateForm',
            header: `${infoSpace} ${intl.formatMessage({id:messages.date_limite})}`,
            size:130,
            muiTableBodyCellProps: {
                align: 'center', //change head cell props
            },
        },
        {
            accessorKey: 'obligatoire',
            filterVariant: 'checkbox',
            size:100,
            header: `${infoSpace} ${intl.formatMessage({id:messages.schooling_fees_obligatory})}`,
            muiTableBodyCellProps: {
                align: 'center', //change head cell props
            },
            // @ts-ignore
            Cell: ({cell}) => {
                // @ts-ignore
                return cell.getValue<boolean>() ? <MyBadge badgeContent={intl.formatMessage({id:messages.oui})} color="success" /> :
                    <MyBadge badgeContent={intl.formatMessage({id:messages.non})} color="error" />;
            },
        },
    ];
};
