import React, {useEffect} from 'react';
import SchoolingSubMenu from "../SchoolingSubMenu";
import Box from "@mui/material/Box";
import {useForm} from "react-hook-form";
import _ from "lodash";
import {yupResolver} from "@hookform/resolvers/yup";
import scolariteStyles from "../jss/scolarite-jss";
import moment from "moment/moment";
import ScolariteEcheancierEditForm from "./ScolariteEcheancierEditForm";
import ScolariteEcheancierList from './ScolariteEcheancierList';
import ScolariteAutresfraisDeleteForm from "./ScolariteEcheancierDeleteForm";
import {scolariteEcheancierDataImpl, scolariteEcheancierSchema} from '../../../constants/type/ScolariteEcheancier';
import useScolariteEcheancierVariables from './businesslogic/ScolariteEcheancierVariables';
import scolariteEcheancierBusinessLogic from "./businesslogic/ScolariteEcheancierBusinessLogic";
import {withSnackbar} from "../../../components/ui/snackbar/SnackbarHOC";
import ScolariteEcheancierDetailsEditForm from "./ScolariteEcheancierDetailsEditForm";

const useBusinessLogic = (props: any): any => {
    const styleClasses = scolariteStyles();
    const formRef = React.useRef<any>(useForm({
        defaultValues:_.cloneDeep(scolariteEcheancierDataImpl),
        // @ts-ignore
        resolver: yupResolver(scolariteEcheancierSchema),
    }));


    return {
        theme: props.theme,
        styleClasses: styleClasses,
        formRef,
        ...useScolariteEcheancierVariables({styleClasses, ...props})
    }
};

export const ScolariteEcheancierContext = React.createContext({});
function ScolariteEcheancier(props:any) {
    const logic = useBusinessLogic(props);
    const styleClasses = logic.styleClasses;



    useEffect(() => {
        moment.locale(logic.activeLang);
        scolariteEcheancierBusinessLogic.initPostConstruct(logic);

        return () => logic.storeState(logic.tableConfigRef.current.getState());
    }, []);



    return (
        <div className={styleClasses.root}>
            <Box className={styleClasses.buttonContainer}>
                <SchoolingSubMenu classes={styleClasses} selected="scolaritecheancier"/>
            </Box>

            <ScolariteEcheancierContext.Provider value={logic}>
                <ScolariteEcheancierList />
            </ScolariteEcheancierContext.Provider>
            <ScolariteEcheancierContext.Provider value={logic}>
                <ScolariteEcheancierEditForm />
            </ScolariteEcheancierContext.Provider>
            <ScolariteEcheancierContext.Provider value={logic}>
                <ScolariteAutresfraisDeleteForm />
            </ScolariteEcheancierContext.Provider>
            <ScolariteEcheancierContext.Provider value={logic}>
                <ScolariteEcheancierDetailsEditForm />
            </ScolariteEcheancierContext.Provider>
        </div>
    );
}

export default withSnackbar(ScolariteEcheancier);
