import {Common, commonDataImp} from "./Common";
import * as yup from "yup";
import {Person, PERSON_CONSTANT, personDataImpl} from "./Person";
import {FonctionPerson} from "./FonctionPerson";
import _ from "lodash";
import {Child} from "./Child";
import {AccountEtat} from "./AccountEtat";
import {FormattedMessage} from "react-intl";
import messages from "../../page/messages/general/messages";
import React from "react";
import {MyBadge} from "../../components/ui/badge/MyBadge";


export const PARENT_CONSTANT = {
    convertToFrontEnd: (logic:any, item:Parent) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(parentDataImpl);
        const data = {...itemInit, ...item} ;
        data.person = PERSON_CONSTANT.convertToFrontEnd(logic, data.person);

        //console.log("item.enfantsIds >>> ", item.enfantsIds);
        if(_.isObject(logic.childrenRef.current)){
            const enfantNames: string[] = [];
            data.enfantsIds?.forEach(id => {
                const enfant: Child = _.find(logic.childrenRef.current, {id:id});
                if(_.isObject(enfant)){
                    enfantNames.push(`${enfant.person?.prenom} ${enfant.person?.nom}`);
                }
            });
            data.enfantsName= _.join(enfantNames, ', ');
        }
        /*data.userAccountEtatForm = '' ;
        if(data.userAccountEtat === AccountEtat.OK){
            data.userAccountEtatForm =  <MyBadge badgeContent={<FormattedMessage id={messages.accountetat_ok} />} color="success" /> ;
        }
        else if(data.userAccountEtat === AccountEtat.LOCKED){
            data.userAccountEtatForm =  <MyBadge badgeContent={<FormattedMessage id={messages.accountetat_locked} />} color="secondary" /> ;
        }
        else if(data.userAccountEtat === AccountEtat.SUSPENDED){
            data.userAccountEtatForm =  <MyBadge badgeContent={<FormattedMessage id={messages.accountetat_suspended} />} color="error" /> ;
        }*/

        return data ;
    },
    convertToBackEnd: (item:Parent) => item
};

export  const parentDataImpl: Parent = {
    id: null,
    userId:null,
    uuid:null,
    person: _.cloneDeep(personDataImpl),
    fonctions: [],
    enfantsIds:[],
    userAccountEtat: AccountEtat.PENDING_VALIDATION,
    enfantsName: ``,
    //userAccountEtatForm: ``,
    common: _.cloneDeep(commonDataImp),
}


export const parentSchema = yup.object().shape({
    id: yup.number().nullable(),
    userId: yup.number().nullable(),
    uuid: yup.string().nullable().default(null),
    person: yup.mixed<Person>().default(_.cloneDeep(personDataImpl)),
    fonctions: yup.array().of(yup.mixed<FonctionPerson>()).nonNullable(),
    userAccountEtat: yup.mixed<AccountEtat>().oneOf(Object.values(AccountEtat)).default(AccountEtat.OK),
    enfantsIds: yup.array().of(yup.number()).nonNullable(),
    enfantsName: yup.string(),
    //userAccountEtatForm: yup.mixed<any>(),
    common: yup.mixed<Common>().default(_.cloneDeep(commonDataImp)),
});

export type Parent = yup.InferType<typeof parentSchema>;
