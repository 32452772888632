import React, { useState, useCallback, useMemo } from 'react'
import { Calendar } from 'react-big-calendar'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'moment/locale/fr'
import 'moment/locale/es'
import 'moment/locale/ar'


const langOption:any = {
    en: null,
    fr: {
        week: 'Semaine',
        work_week: 'Semaine de travail',
        day: 'Jour',
        month: 'Mois',
        previous: 'Précédent',
        next: 'Suivant',
        today: `Aujourd'hui`,
        agenda: 'Agenda',

        showMore: (total:any) => `+${total} plus`,
    },
}

export default function BigCalendar(props:any) {
    const { locale, localizer, events, addButton, components, onSelectEvent } = props
    const activeLanguage = locale.split('-')
    const [eventsList, setEventsList] = useState(events)

    const { defaultDate, messages } = useMemo(
        () => ({
            defaultDate: new Date(2015, 2, 1),
            messages: langOption[activeLanguage[0]],
        }),
        [activeLanguage[0]]
    )

    const handleSelectSlot = useCallback(
        ({ start, end } : { start:any, end:any}) => {
            //addButton()


            // const title = window.prompt('New Event name')
            // if (title) {
            //     setEventsList((prev:any) => [...prev, { start, end, title }])
            // }
        },
        [setEventsList]
    )

    // const handleSelectEvent = useCallback(
    //     (event:any) => window.alert(event.title),
    //     []
    // )

    return (
        // @ts-ignore
        <Calendar
            style={{ height: 600 }}
            selectable
            culture={activeLanguage[0]}
            defaultDate={defaultDate}
            events={eventsList}
            localizer={localizer}
            messages={messages}
            onSelectEvent={onSelectEvent}
            onSelectSlot={handleSelectSlot}
            components={components}
        />
    )
}
