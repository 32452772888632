import _ from "lodash";
import {Common, commonDataImp} from "./Common";
import * as yup from "yup";
import {
    columnsScolariteTypePlugin, columnsScolariteTypePlugin2,
    excelScolariteTypeDataMappingPlugin,
    ScolariteType,
    scolariteTypeCustomOptionsPlugin, scolariteTypeCustomOptionsPlugin2,
    scolariteTypeDataImpl
} from "./ScolariteType";
import {Badge} from "@mui/material";
import {FormattedMessage} from "react-intl";
import messages from "../../page/messages/general/messages";
import React from "react";
import {Profils, profilsDataImpl} from "./Profils";
import {isColumnVisible, isPositiveValue} from "../../helpers/helpers";
import moment from "moment/moment";
import {
    columnsGroupPlugin, columnsGroupPlugin2,
    excelGroupDataMappingPlugin,
    Groupesection,
    groupeSectionCustomOptionsPlugin, groupeSectionCustomOptionsPlugin2,
    groupesectionDataImpl
} from "./Groupesection";
import {
    columnsScolariteAnneePlugin, columnsScolariteAnneePlugin2,
    ScolariteAnnee,
    scolariteAnneeCustomOptionsPlugin, scolariteAnneeCustomOptionsPlugin2,
    scolariteAnneeDataImpl
} from "./ScolariteAnnee";
import {MyBadge} from "../../components/ui/badge/MyBadge";

export const SCOLARITEGROUP_CONSTANT = {
    convertToFrontEnd: (logic:any, item:ScolariteGroup) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(scolariteGroupDataImpl);
        const data = {...itemInit, ...item} ;

       // data.montantForm        = <b>{new Intl.NumberFormat("fr-FR").format(data.montant)}</b> ;

        data.groupesectionForm      = _.find(logic.groupesectionsRef.current, {id:data.groupesectionId}) ?? _.cloneDeep(groupesectionDataImpl);
        data.groupesectionIds       = [];
        data.groupesectionIds.push(data.groupesectionId ?? 0);

        data.ladateForm             = isPositiveValue(data.ladate) ? moment(data.ladate) : null;
        // @ts-ignore
        data.liredateForm         = _.isObject(data.ladateForm) ?  data.ladateForm.format("L") : '';

        // @ts-ignore
        data.scolariteTypeForm      = _.find(logic.scolariteTypeRef.current, {id:data.scolariteTypeId}) ?? _.cloneDeep(scolariteTypeDataImpl);

        data.scolariteAnneeForm     = _.find(logic.scolariteAnneesRef.current, {id:data.scolariteAnneeId}) ?? _.cloneDeep(scolariteAnneeDataImpl);

        data.scolariteAnNomForm = data.scolariteAnneeForm?.nom ?? ``;

        if(isPositiveValue(data?.profilsId)){
            // @ts-ignore
            data.profils = _.find(logic.profilsRef?.current, {id: data?.profilsId}) ?? _.cloneDeep(profilsDataImpl);
            data.profilsName = data.profils?.nom;
        }

        return data ;
    },
    convertToBackEnd: (logic:any, item:ScolariteGroup) => item,
};



export  const scolariteGroupDataImpl: ScolariteGroup = {
    id: null,
    nom: ``,
    scolariteTypeId: 0,
    scolariteTypeTag: null,
    groupesectionIds: [],
    profilsId: 0,
    profils: _.cloneDeep(profilsDataImpl),
    profilsName: ``,
    groupesectionId: 0,
    montant: 0,
    ladate: moment().startOf('day').valueOf(),
    scolariteAnneeId:0,
    obligatoire: false,
    echeance: false,
    extrascolaireCode: null,
    extrascolaireId: null,
    groupesectionForm: null,
    scolariteTypeForm: null,
    ladateForm: moment().startOf('day'),
    liredateForm: '',
    scolariteAnNomForm:'',
    scolariteAnneeForm: _.cloneDeep(scolariteAnneeDataImpl),
    common: _.cloneDeep(commonDataImp),

}

export const scolariteGroupSchema = yup.object().shape({
    id: yup.number().nullable(),
    nom: yup.string().required(),
    code: yup.string().nullable().notRequired(),
    scolariteAnneeId: yup.number().min(1).required(),
    scolariteTypeId: yup.number().nonNullable().min(1).required(),
    scolariteTypeTag: yup.string().notRequired(),
    groupesectionIds: yup.array().of(yup.number()).nonNullable().default([]).min(1).required(),
    profilsId: yup.number().notRequired(),
    ladate: yup.number().notRequired(),
    profilsName: yup.string().notRequired(),
    profils: yup.mixed<Profils>().nonNullable().default(_.cloneDeep(profilsDataImpl)).notRequired(),
    groupesectionId: yup.number().nonNullable().default(0).notRequired(),
    montant: yup.number().default(0).min(1).required(),
    obligatoire: yup.boolean().default(false).notRequired(),
    echeance: yup.boolean().default(false).notRequired(),
    extrascolaireCode: yup.string().notRequired().nullable().notRequired(),
    extrascolaireId: yup.number().notRequired().nullable().notRequired(),
    groupesectionForm: yup.mixed<Groupesection>().nullable().notRequired(),
    ladateForm: yup.mixed<any>().notRequired(),
    liredateForm: yup.string().nonNullable().notRequired(),
    scolariteAnneeForm: yup.mixed<ScolariteAnnee>().nonNullable().default(_.cloneDeep(scolariteAnneeDataImpl)).notRequired(),
    scolariteAnNomForm: yup.string().notRequired(),
    //lireObligatoireForm: yup.mixed<Object>(),
    //lireEcheanceForm: yup.mixed<Object>(),
    scolariteTypeForm: yup.mixed<ScolariteType>().nullable().notRequired(),
    common: yup.mixed<Common>(),

});


export const excelScolariteGroupDataMapping =  (
    {rows=[], columns=[],  table={}, intl={}, infoSpace=``, scolariteTypeSpace=``, groupSpace=``} :
        {rows:any[]; columns:any[]; table:any; intl:any; infoSpace: string; scolariteTypeSpace: string; groupSpace: string; }
) => {
    return _.map(rows, (row:any) => {
        let obj:any = {};
        const data = row.original;

        obj = excelGroupDataMappingPlugin({obj, row:data, columns, prefix:`groupesectionForm.`, table, intl, infoSpace:intl.formatMessage({id:messages.table_h_group})});
        obj = excelScolariteTypeDataMappingPlugin({obj, row:data, columns, prefix:`scolariteTypeForm.`, table, intl, infoSpace:intl.formatMessage({id:messages.schooling_fees_type})});

        if(isColumnVisible(columns, `scolariteAnNomForm`)){
            obj[`${intl.formatMessage({id:messages.schooling_fees_title})}`] = data.scolariteAnNomForm;
        }
        if(isColumnVisible(columns, `nom`)){
            obj[`${intl.formatMessage({id:messages.schooling_fees_title})}`] = data.nom;
        }
        if(isColumnVisible(columns, `code`)){
            obj[`${intl.formatMessage({id:messages.header_code})}`] = data.code;
        }
        if(isColumnVisible(columns, `montant`)){
            obj[`${infoSpace} ${intl.formatMessage({id:messages.table_h_montant})}`] = data.montant;
        }
        if(isColumnVisible(columns, `profilsName`)){
            obj[`${intl.formatMessage({id:messages.table_h_profil})}`] = data.profilsName;
        }
        if(isColumnVisible(columns, `obligatoire`)){
            obj[`${intl.formatMessage({id:messages.schooling_fees_obligatory})}`] = data.obligatoire;
        }
        if(isColumnVisible(columns, `echeance`)){
            obj[`${intl.formatMessage({id:messages.schooling_fees_echeance})}`] = data.echeance;
        }
        if(isColumnVisible(columns, `liredateForm`)){
            obj[`${intl.formatMessage({id:messages.payment_date_limite})}`] = data.liredateForm;
        }
        return obj;
    });
};
export const excelScolariteGroupDataMappingPlugin = ({obj={}, row={}, intl={}, infoSpace=``, columns=[], table={}, prefix=``}:{obj:any; row:any; intl:any; infoSpace:string; columns:any[]; table:any; prefix:string;}) => {
    const data = row?.scolariteGroupForm;
    if (_.isObject(data) === false) {
        return obj;
    }
    obj = excelGroupDataMappingPlugin({obj, row:data, table, intl, columns, prefix: `${prefix}groupesectionForm.`, infoSpace: intl.formatMessage({id: messages.table_h_group})
    });
    obj = excelScolariteTypeDataMappingPlugin({obj, row: data, columns, table, intl, prefix: `${prefix}scolariteTypeForm.`, infoSpace: intl.formatMessage({id: messages.schooling_fees_type})
    });

    if(isColumnVisible(columns, `scolariteAnNomForm`)){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.schooling_fees_title})}`] = data.scolariteAnNomForm;
    }
    if(isColumnVisible(columns, 'nom')){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.schooling_fees_title})}`] = data.nom;
    }
    if(isColumnVisible(columns, 'code')){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.header_code})}`] = data.code;
    }
    if(isColumnVisible(columns, 'montant')){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.table_h_montant})}`] = data.montant;
    }
    if(isColumnVisible(columns, 'obligatoire')){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.schooling_fees_obligatory})}`] = data.obligatoire;
    }
    if(isColumnVisible(columns, 'echeance')){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.schooling_fees_echeance})}`] = data.echeance;
    }
    if(isColumnVisible(columns, 'liredateForm')){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.payment_date_limite})}`] = data.liredateForm;
    }
    return obj;
};

export const scolariteGroupCustomOptions = {
    columnVisibility : {
        ...groupeSectionCustomOptionsPlugin.columnVisibility,
        ...scolariteAnneeCustomOptionsPlugin.columnVisibility,
        ...scolariteTypeCustomOptionsPlugin.columnVisibility,
        'code': false,
        'obligatoire': false,
        'echeance': false,
    }
} ;

export const scolariteGroupCustomOptionsPlugins = {
    columnVisibility : {
        ...groupeSectionCustomOptionsPlugin.columnVisibility,
        ...scolariteAnneeCustomOptionsPlugin.columnVisibility,
        ...scolariteTypeCustomOptionsPlugin.columnVisibility,
        'scolariteGroupForm.nom': false,
        'scolariteGroupForm.code': false,
        'scolariteGroupForm.scolariteTypeForm.nom': false,
        'scolariteGroupForm.scolariteTypeForm.code': false,
        'groupesectionForm.nom': false,
        'groupesectionForm.code': false,
        'scolariteGroupForm.liredateForm': false,
        'scolariteGroupForm.obligatoire': false,
        'scolariteGroupForm.echeance': false,
        'scolariteGroupForm.profilsName': false,
        'scolariteGroupForm.montant': false,
    }
} ;

export const scolariteGroupCustomOptionsPlugins2 = {
    columnVisibility : {
        ...groupeSectionCustomOptionsPlugin2.columnVisibility,
        ...scolariteAnneeCustomOptionsPlugin2.columnVisibility,
        ...scolariteTypeCustomOptionsPlugin2.columnVisibility,
        'scolariteGroupForm_nom': false,
        'scolariteGroupForm_code': false,
        'groupesectionForm_nom': false,
        'groupesectionForm_code': false,
        'scolariteGroupForm_liredateForm': false,
        'scolariteGroupForm_obligatoire': false,
        'scolariteGroupForm_echeance': false,
        'scolariteGroupForm_profilsName': false,
        'scolariteGroupForm_montant': false,
    }
} ;



export const columnsScolariteGroup = (props:any) => {
    const {intl, nomSpace, scolariteTypeSpace, groupSpace} = props ;
    const infoSpace = nomSpace ?? ``;
    const groupSectionOptions = {
        nomSize: 80,
        codeSize: 80,
    }
    const scolariteAnneeOptions = {
        nomSize: 80,
        codeSize: 80,
    }
    const scolariteTypeOptions = {
        nomSize: 80,
        codeSize: 80,
    }
    return [
        ...columnsGroupPlugin({intl, nomSpace: intl.formatMessage({id:messages.table_h_group}), options:groupSectionOptions, prefixKey:'groupesectionForm.'}),
        ...columnsScolariteAnneePlugin({intl, nomSpace: intl.formatMessage({id:messages.table_h_scolarite}), options:scolariteAnneeOptions, prefixKey:'scolariteAnneeForm.'}),
        ...columnsScolariteTypePlugin({intl, nomSpace: intl.formatMessage({id:messages.schooling_fees_type}), options:scolariteTypeOptions, prefixKey:'scolariteTypeForm.'}),
        /*{
            accessorKey: 'scolariteAnNomForm',
            header: `${infoSpace} ${intl.formatMessage({id:messages.table_h_scolarite})}`,
            size: 120,
        },*/
        {
            accessorKey: 'nom',
            header: `${infoSpace} ${intl.formatMessage({id:messages.schooling_fees_title})}`,
            size: 120,
        },
        {
            accessorKey: 'code',
            header: `${infoSpace} ${intl.formatMessage({id:messages.header_code})}`,
            size: 120,
        },
        {
            accessorKey: 'profilsName',
            header: `${infoSpace} ${intl.formatMessage({id:messages.table_h_profil})}`,
            size: 80,
        },
        {
            accessorKey: 'liredateForm',
            header: `${infoSpace} ${intl.formatMessage({id:messages.date_limite})}`,
            muiTableBodyCellProps: {
                align: 'center', //change head cell props
            },
            size:100,
        },
        {
            accessorKey: 'obligatoire',
            header: `${infoSpace} ${intl.formatMessage({id: messages.schooling_fees_obligatory})}`,
            muiTableBodyCellProps: {
                align: 'center', //change head cell props
            },
            size:80,
            // @ts-ignore
            Cell: ({cell}) => {
                // @ts-ignore
                return cell.getValue<boolean>() ? <MyBadge badgeContent={intl.formatMessage({id:messages.oui})} color="success" /> :
                    <MyBadge badgeContent={intl.formatMessage({id:messages.non})} color="error" />;
            },
        },
        {
            accessorKey: 'echeance',
            header: `${infoSpace} ${intl.formatMessage({id: messages.schooling_fees_echeance})}`,
            muiTableBodyCellProps: {
                align: 'center', //change head cell props
            },
            size:80,
            // @ts-ignore
            Cell: ({cell}) => {
                // @ts-ignore
                return  cell.getValue<boolean>() ? <MyBadge badgeContent={intl.formatMessage({id:messages.oui})} color="success" /> :
                    <MyBadge badgeContent={intl.formatMessage({id:messages.non})} color="error" />;
            },
        },
        {
            accessorKey: 'montant',
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_montant})}`,
            size:120,
            filterVariant: 'range',
            filterFn: 'between',
            muiTableBodyCellProps: {
                align: 'right', //change head cell props
            },
            // @ts-ignore
            Cell: ({cell}) => <b>{new Intl.NumberFormat("fr-FR").format(cell.getValue<number>())}</b>,
        },
    ];
} ;


export const columnsScolariteGroupPlugin = (props:any) => {
    const {intl, nomSpace, prefixKey} = props ;
    const prefix = prefixKey ?? ``;
    const infoSpace = nomSpace ?? ``;
    const groupSectionOptions = {
        nomSize: 80,
        codeSize: 80,
    }
    const scolariteTypeOptions = {
        nomSize: 80,
        codeSize: 80,
    }
    return [
        ...columnsGroupPlugin({intl, nomSpace: intl.formatMessage({id:messages.table_h_group}), options:groupSectionOptions, prefixKey:`${prefix}groupesectionForm.`}),
        ...columnsScolariteTypePlugin({intl, nomSpace: intl.formatMessage({id:messages.schooling_fees_type}), options:scolariteTypeOptions, prefixKey:`${prefix}scolariteTypeForm.`}),
        {
            accessorKey: `${prefix}scolariteAnneeForm.nom`,
            header: `${infoSpace} ${intl.formatMessage({id:messages.table_h_scolarite})}`,
            muiTableBodyCellProps: {
                align: 'center', //change head cell props
            },
            size:80,
        },
        {
            accessorKey: `${prefix}nom`,
            header: `${infoSpace} ${intl.formatMessage({id:messages.schooling_fees_title})}`,
            size: 120,
        },
        {
            accessorKey: `${prefix}code`,
            header: `${infoSpace} ${intl.formatMessage({id:messages.header_code})}`,
            size: 120,
        },
        {
            accessorKey: `${prefix}profilsName`,
            header: `${infoSpace} ${intl.formatMessage({id:messages.table_h_profil})}`,
            size: 80,
        },
        {
            accessorKey: `${prefix}liredateForm`,
            header: `${infoSpace} ${intl.formatMessage({id:messages.date_limite})}`,
            muiTableBodyCellProps: {
                align: 'center', //change head cell props
            },
            size:100,
        },
        {
            accessorKey: `${prefix}obligatoire`,
            header: `${infoSpace} ${intl.formatMessage({id: messages.schooling_fees_obligatory})}`,
            muiTableBodyCellProps: {
                align: 'center', //change head cell props
            },
            // @ts-ignore
            Cell: ({cell}) => {
                // @ts-ignore
                const isObligatoire = cell.getValue<boolean>();
                isObligatoire ? <MyBadge badgeContent={<FormattedMessage id={messages.oui} />} color="success" /> : <Badge badgeContent={<FormattedMessage id={messages.non} />} color="error" />;
            },
        },
        {
            accessorKey: `${prefix}echeance`,
            header: `${infoSpace} ${intl.formatMessage({id: messages.schooling_fees_echeance})}`,
            muiTableBodyCellProps: {
                align: 'center', //change head cell props
            },
            // @ts-ignore
            Cell: ({cell}) => {
                // @ts-ignore
                const isObligatoire = cell.getValue<boolean>();
                isObligatoire ? <MyBadge badgeContent={<FormattedMessage id={messages.oui} />} color="success" /> : <Badge badgeContent={<FormattedMessage id={messages.non} />} color="error" />;
            },
        },
        {
            accessorKey: `${prefix}montant`,
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_montant})}`,
            size:120,
            filterVariant: 'range',
            filterFn: 'between',
            muiTableBodyCellProps: {
                align: 'right', //change head cell props
            },
            // @ts-ignore
            Cell: ({cell}) => <b>{new Intl.NumberFormat("fr-FR").format(cell.getValue<number>())}</b>,
        },
    ];
} ;

export const columnsScolariteGroupPlugin2 = (props:any) => {
    const {intl, nomSpace} = props ;
    const infoSpace = nomSpace ?? ``;
    const groupSectionOptions = {
        nomSize: 80,
        codeSize: 80,
    }
    const scolariteTypeOptions = {
        nomSize: 80,
        codeSize: 80,
    }
    const scolariteAnOptions = {
        nomSize: 80,
        codeSize: 80,
    }
    return [
        ...columnsScolariteAnneePlugin2({intl, nomSpace: intl.formatMessage({id:messages.table_h_scolarite}), options:scolariteAnOptions}),
        ...columnsGroupPlugin2({intl, nomSpace: intl.formatMessage({id:messages.table_h_group}), options:groupSectionOptions}),
        ...columnsScolariteTypePlugin2({intl, nomSpace: intl.formatMessage({id:messages.schooling_fees_type}), options:scolariteTypeOptions}),
        {
            accessorKey: 'scolariteGroupForm_nom',
            header: `${infoSpace} ${intl.formatMessage({id:messages.schooling_fees_title})}`,
            size: 120,
        },
        {
            accessorKey: 'scolariteGroupForm_code',
            header: `${infoSpace} ${intl.formatMessage({id:messages.header_code})}`,
            size: 120,
        },
        {
            accessorKey: 'scolariteGroupForm_profilsName',
            header: `${infoSpace} ${intl.formatMessage({id:messages.table_h_profil})}`,
            size: 80,
        },
        {
            accessorKey: 'scolariteGroupForm_liredateForm',
            header: `${infoSpace} ${intl.formatMessage({id:messages.date_limite})}`,
            muiTableBodyCellProps: {
                align: 'center', //change head cell props
            },
            size:100,
        },
        {
            accessorKey: 'scolariteGroupForm_obligatoire',
            header: `${infoSpace} ${intl.formatMessage({id: messages.schooling_fees_obligatory})}`,
            muiTableBodyCellProps: {
                align: 'center', //change head cell props
            },
            // @ts-ignore
            Cell: ({cell}) => {
                // @ts-ignore
                const isObligatoire = cell.getValue<boolean>();
                isObligatoire ? <MyBadge badgeContent={<FormattedMessage id={messages.oui} />} color="success" /> : <Badge badgeContent={<FormattedMessage id={messages.non} />} color="error" />;
            },
        },
        {
            accessorKey: 'scolariteGroupForm_echeance',
            header: `${infoSpace} ${intl.formatMessage({id: messages.schooling_fees_echeance})}`,
            muiTableBodyCellProps: {
                align: 'center', //change head cell props
            },
            // @ts-ignore
            Cell: ({cell}) => {
                // @ts-ignore
                const isObligatoire = cell.getValue<boolean>();
                isObligatoire ? <MyBadge badgeContent={<FormattedMessage id={messages.oui} />} color="success" /> : <Badge badgeContent={<FormattedMessage id={messages.non} />} color="error" />;
            },
        },
        {
            accessorKey: 'scolariteGroupForm_montant',
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_montant})}`,
            size:120,
            filterVariant: 'range',
            filterFn: 'between',
            muiTableBodyCellProps: {
                align: 'right', //change head cell props
            },
            // @ts-ignore
            Cell: ({cell}) => <b>{new Intl.NumberFormat("fr-FR").format(cell.getValue<number>())}</b>,
        },
    ];
} ;

export type ScolariteGroup = yup.InferType<typeof scolariteGroupSchema>;

