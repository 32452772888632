import {EnfantParent, ENFANTPARENT_CONSTANT} from "./EnfantParent";
import {Common, commonDataImp} from "./Common";
import _ from "lodash";
import * as yup from "yup";
import {EtatParent} from "./EtatParent";
import {Person, PERSON_CONSTANT, personDataImpl} from "./Person";
import {DroitImage} from "./DroitImage";
import {Eleve} from "./Eleve";
import {DossierEnfant} from "./DossierEnfant";
import {PersonneAutoriserEnfant} from "./PersonneAutoriserEnfant";
import {Sante} from "./Sante";
import {EnfantProfils, ENFANTPROFILS_CONSTANT} from "./EnfantProfils";
import {Profils} from "./Profils";
import messages from "../../page/messages/general/messages";
import React from "react";
import {isColumnVisible, isNotBlank, stringToColor} from "../../helpers/helpers";
import {Avatar, Box} from "@mui/material";


export const CHILD_CONSTANT = {
    convertToFrontEnd: (logicRacine:any, item:Child) => {
        const itemInit = _.cloneDeep(childDataImpl);
        const data = {...itemInit, ...item} ;

        data.person = PERSON_CONSTANT.convertToFrontEnd(logicRacine, data.person ?? _.cloneDeep(personDataImpl)) ;

        // @ts-ignore
        data.enfantParents = _.map(data.enfantParents, opt => ENFANTPARENT_CONSTANT.convertToFrontEnd(logicRacine, opt));
        // @ts-ignore
        data.classeNameForm = data.eleves[0]?.classe?.nom ?? '' ;
        // @ts-ignore
        data.childNomPrenomForm = data.person.nomPrenom;
        // @ts-ignore
        data.childnomForm = data.person.nom;
        // @ts-ignore
        data.childprenomForm = data.person.prenom;
        // @ts-ignore
        data.childdatenaissanceForm = data.person.dateNaissanceForm;
        // @ts-ignore
        data.childsexeForm = data.person.sexe;
        // @ts-ignore
        data.childphotoForm = data.person.photoForm;
        // @ts-ignore
        data.childnoteForm = data.person.note;
        // @ts-ignore
        data.childpaysForm = data.person.paysForm;

        const nomUrgence1 = isNotBlank(data.emergencyNom1) ? `${data.emergencyNom1}, ` : ``;
        const nomUrgence2 = isNotBlank(data.emergencyNom2) ? `${data.emergencyNom2}, ` : ``;

        const phoneUrgence1 = isNotBlank(data.emergencyPhone1) ? `Tel: ${data.emergencyPhone1}` : ``;
        const phoneUrgence2 = isNotBlank(data.emergencyPhone2) ? `Tel: ${data.emergencyPhone2}, ` : ``;

        data.childemergencyNom1Form = `${nomUrgence1} ${phoneUrgence1}`;
        data.childemergencyNom2Form = `${nomUrgence2} ${phoneUrgence2}`;


        // @ts-ignore
        data.enfantProfils  = _.map(data.enfantProfils, enfantprofil => ENFANTPROFILS_CONSTANT.convertToFrontEnd(logicRacine, enfantprofil));

        let childprofil = ``;
        if(_.isEmpty(logicRacine.profilsRef?.current) === false){
            data.profils = [];
            data.enfantProfils.forEach(enfantprofil => {
                const profiltem: Profils = _.find(logicRacine.profilsRef?.current, {id:enfantprofil?.profilsId});
                if(_.isObject(profiltem)){
                    // @ts-ignore
                    data.profils.push(profiltem);
                    childprofil = `${profiltem.nom}, ${childprofil}`;
                }
            });
        }
        data.childProfilForm = childprofil;
        // @ts-ignore
       return data;
    },
    convertToBackEnd: (item:Child) => item
};



export  const childDataImpl: Child = {
    id: null,
    emergencyPhone1: '',
    emergencyPhone2: '',
    emergencyNom1: '',
    emergencyNom2: '',
    foodRestriction: '',
    situationParent: EtatParent.ENSEMBLE,
    healthDetail: '',
    allergy: '',
    person: personDataImpl,
    enfantParents: [],
    droitImages: [],
    eleves: [],
    dossierEnfants: [],
    personneAutoriserEnfants: [],
    enfantProfils: [],
    profils: [],
    santes:  [],
    common: _.cloneDeep(commonDataImp),
    classeNameForm: ``,
    childNomPrenomForm: ``,
    childnomForm: ``,
    childprenomForm: ``,
    childdatenaissanceForm: ``,
    childsexeForm: ``,
    childphotoForm: ``,
    childnoteForm: ``,
    childemergencyNom1Form: ``,
    childemergencyNom2Form: ``,
    childpaysForm: ``,
    childProfilForm: ``,
}


export const childSchema = yup.object().shape({
    id: yup.number().nullable(),
    emergencyPhone1: yup.string().nullable(),
    emergencyPhone2: yup.string().nullable(),
    emergencyNom1: yup.string().nullable(),
    emergencyNom2: yup.string().nullable(),
    situationParent: yup.mixed<EtatParent>().oneOf(Object.values(EtatParent)).nullable(),
    foodRestriction: yup.string().nullable(),
    healthDetail: yup.string().nullable(),
    allergy: yup.string().nullable(),
    person: yup.mixed<Person>().nonNullable(),
    enfantParents: yup.array().of(yup.mixed<EnfantParent>()).nonNullable().default([]),
    droitImages: yup.array().of(yup.mixed<DroitImage>()).nonNullable().default([]),
    eleves: yup.array().of(yup.mixed<Eleve>()).nonNullable().default([]),
    dossierEnfants: yup.array().of(yup.mixed<DossierEnfant>()).nonNullable().default([]),
    personneAutoriserEnfants: yup.array().of(yup.mixed<PersonneAutoriserEnfant>()).nonNullable().default([]),
    enfantProfils: yup.array().of(yup.mixed<EnfantProfils>()).nonNullable().default([]),
    profils: yup.array().of(yup.mixed<Profils>()).nonNullable().default([]),
    santes:  yup.array().of(yup.mixed<Sante>()).nonNullable().default([]),
    photoForm: yup.string(),
    common: yup.mixed<Common>(),
    classeNameForm: yup.string().default(''),
    childNomPrenomForm: yup.string().default(''),
    childnomForm: yup.string().default(''),
    childprenomForm: yup.string().default(''),
    childdatenaissanceForm: yup.string().default(''),
    childsexeForm: yup.string().default(''),
    childphotoForm: yup.string().default(''),
    childnoteForm: yup.string().default(''),
    childemergencyNom1Form: yup.string().default(''),
    childemergencyNom2Form: yup.string().default(''),
    childpaysForm: yup.string().default(''),
    childProfilForm: yup.string().default(''),
});

export const childCustomOptions = {
    columnVisibility : {
        'foodRestriction': false,
        'healthDetail': false,
        'allergy': false,
        'classeNameForm': false,
        'childnomForm': false,
        'childprenomForm': false,
        'childdatenaissanceForm': false,
        'childsexeForm': false,
        'childnoteForm': false,
        'childemergencyNom1Form': false,
        'childemergencyNom2Form': false,
        'childpaysForm': false,
        'childProfilForm': false,
    }
} ;


export const childCustomOptionsPlugins = {
    columnVisibility : {
        'childForm.childNomPrenomForm': false,
        'childForm.foodRestriction': false,
        'childForm.healthDetail': false,
        'childForm.allergy': false,
        'childForm.classeNameForm': false,
        'childForm.childnomForm': false,
        'childForm.childprenomForm': false,
        'childForm.childdatenaissanceForm': false,
        'childForm.childsexeForm': false,
        'childForm.childnoteForm': false,
        'childForm.childemergencyNom1Form': false,
        'childForm.childemergencyNom2Form': false,
        'childForm.childpaysForm': false,
        'childForm.childProfilForm': false,
    }
} ;

export const childCustomOptionsPlugins2 = {
    columnVisibility : {
        'childForm_childNomPrenomForm': false,
        'childForm_foodRestriction': false,
        'childForm_healthDetail': false,
        'childForm_allergy': false,
        'childForm_classeNameForm': false,
        'childForm_childnomForm': false,
        'childForm_childprenomForm': false,
        'childForm_childdatenaissanceForm': false,
        'childForm_childsexeForm': false,
        'childForm_childnoteForm': false,
        'childForm_childemergencyNom1Form': false,
        'childForm_childemergencyNom2Form': false,
        'childForm_childpaysForm': false,
        'childForm_childProfilForm': false,
    }
} ;

export const excelChildDataMapping = (rows:any, intl:any, infoSpace:string) => {
    return _.map(rows, (row: any) => {
        let obj: any = {};
        const data = row.original;
        obj[intl.formatMessage({id: messages.table_h_fullname})] = data.childNomPrenomForm ?? ``;
        obj[intl.formatMessage({id: messages.table_h_child_lastname})] = data.childnomForm;
        obj[intl.formatMessage({id: messages.table_h_child_firstname})] = data.childprenomForm;
        obj[intl.formatMessage({id: messages.table_h_child_birthday})] = data.childdatenaissanceForm;
        obj[intl.formatMessage({id: messages.table_h_child_gender})] = data.childsexeForm;
        obj[intl.formatMessage({id: messages.classroom_child})] = data.classeNameForm;
        obj[intl.formatMessage({id: messages.country})] = data.childpaysForm;
        obj[`${intl.formatMessage({id: messages.emergency_contact_child})} 1`] = data.childemergencyNom1Form;
        obj[`${intl.formatMessage({id: messages.emergency_contact_child})} 2`] = data.childemergencyNom2Form;
        obj[intl.formatMessage({id: messages.food_restriction_child})] = data.foodRestriction;
        obj[intl.formatMessage({id: messages.health_detail_child})] = data.healthDetail;
        obj[intl.formatMessage({id: messages.allergies})] = data.allergy;
        obj[intl.formatMessage({id: messages.note})] = data.childnoteForm;
        obj[intl.formatMessage({id: messages.schooling_profile})] = data.childProfilForm;
        return obj;
    });
}


export const excelChildDataMappingPlugin = ({obj={}, row={}, intl={}, infoSpace=``, columns=[], table=[], prefix=``}:{obj:any; row:any; intl:any; infoSpace:string; columns: any[]; table: any; prefix: string;}) => {
    const data: Child = row?.childForm;
    if(_.isObject(data) === false){
        return obj ;
    }

    if(isColumnVisible(columns, `${prefix}childNomPrenomForm`)){
        obj[`${infoSpace} ${intl.formatMessage({id: messages.table_h_fullname})}`] = data.childNomPrenomForm ?? ``;
    }
    if(isColumnVisible(columns, `${prefix}childnomForm`)){
        obj[`${infoSpace} ${intl.formatMessage({id: messages.table_h_child_lastname})}`] = data.childnomForm;
    }
    if(isColumnVisible(columns, `${prefix}childprenomForm`)){
        obj[`${infoSpace} ${intl.formatMessage({id: messages.table_h_child_firstname})}`] = data.childprenomForm;
    }
    if(isColumnVisible(columns, `${prefix}childdatenaissanceForm`)){
        obj[`${infoSpace} ${intl.formatMessage({id: messages.table_h_child_birthday})}`] = data.childdatenaissanceForm;
    }
    if(isColumnVisible(columns, `${prefix}childsexeForm`)){
        obj[`${infoSpace} ${intl.formatMessage({id: messages.table_h_child_gender})}`] = data.childsexeForm;
    }
    if(isColumnVisible(columns, `${prefix}classeNameForm`)){
        obj[`${infoSpace} ${intl.formatMessage({id: messages.classroom_child})}`] = data.classeNameForm;
    }
    if(isColumnVisible(columns, `${prefix}childpaysForm`)){
        obj[`${infoSpace} ${intl.formatMessage({id: messages.country})}`] = data.childpaysForm;
    }
    if(isColumnVisible(columns, `${prefix}childemergencyNom1Form`)){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.emergency_contact_child})} 1`] = data.childemergencyNom1Form;
    }
    if(isColumnVisible(columns, `${prefix}childemergencyNom2Form`)){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.emergency_contact_child})} 2`] = data.childemergencyNom2Form;
    }
    if(isColumnVisible(columns, `${prefix}foodRestriction`)){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.food_restriction_child})}`] = data.foodRestriction;
    }
    if(isColumnVisible(columns, `${prefix}healthDetail`)){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.health_detail_child})}`] = data.healthDetail;
    }
    if(isColumnVisible(columns, `${prefix}allergy`)){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.allergies})}`] = data.allergy;
    }
    if(isColumnVisible(columns, `${prefix}childnoteForm`)){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.note})}`] = data.childnoteForm;
    }
    if(isColumnVisible(columns, `${prefix}childProfilForm`)){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.schooling_profile})}`] = data.childProfilForm;
    }
    return obj;
}

export const columnsChild = (props:any) => {
    const {intl, nomSpace} = props ;
    const infoSpace = nomSpace ?? ``;
    const activeLang = intl.locale;
    const tablespace = 'childForm.';
    return [
        // @ts-ignore
        {
            accessorKey: 'childphotoForm',
            header:  `${infoSpace} ${intl.formatMessage({id: messages.table_h_photo})}`,
            enableColumnFilter:false,
            enableColumnOrdering:false,
            grow: false,
            size: 90,
            muiTableBodyCellProps: {
                align: 'center',
            },
            // @ts-ignore
            Cell: ({ cell }) => (
                <div style={{textAlign:'center', width:'80'}}>
                    <Avatar
                        // @ts-ignore
                        src={`${cell.getValue<string>()}`}
                        sx={{ textAlign:'center'}} />
                </div>
            ),
        },
        {
            accessorKey: 'childNomPrenomForm',
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_fullname})}`,
        },
        {
            accessorKey: 'childnomForm',
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_child_lastname})}`,
        },
        {
            accessorKey: 'childprenomForm',
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_child_firstname})}`,
        },
        {
            accessorKey: 'childdatenaissanceForm',
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_child_birthday})}`,
            size: 100,
        },
        { //child.sexeForm = lang === 'en' ? GENDER_EN[0].name : GENDER_FR[0].name;
            accessorKey: 'childsexeForm',
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_child_gender})}`,
            size: 80,
            grow: false,
            // @ts-ignore
            Cell: ({ cell }) =>  {
                // @ts-ignore
                const value = cell.getValue<string>()
                if(value === 'HOMME'){
                    return activeLang === 'en' ? 'Boy' : 'Garçon' ;
                }
                return activeLang === 'en' ? 'Girl' : 'Fille' ;
            },
        },
        {
            accessorKey: 'classeNameForm',
            header: `${infoSpace} ${intl.formatMessage({id: messages.classroom_child})}`,
            size: 120,
        },
        {
            accessorKey: 'childpaysForm',
            header: `${infoSpace} ${intl.formatMessage({id: messages.country})}`,
            size: 120,
            muiTableBodyCellProps: {
                align: 'center', //change head cell props
            },
            // @ts-ignore
            Cell: ({ cell }) => {
                // @ts-ignore
                const value = cell.getValue<any>();
                return (
                    <Box component="span" style={{color: stringToColor(value)}}>
                        {value}
                    </Box>
                );
            },
        },
        {
            accessorKey: 'childemergencyNom1Form',
            header: `${infoSpace} ${intl.formatMessage({id:messages.emergency_contact_child})} 1`,
        },
        {
            accessorKey: 'childemergencyNom2Form',
            header: `${infoSpace} ${intl.formatMessage({id:messages.emergency_contact_child})} 2`,
        },
        {
            accessorKey: 'foodRestriction',
            header: `${infoSpace} ${intl.formatMessage({id:messages.food_restriction_child})}`,
        },
        {
            accessorKey: 'healthDetail',
            header: `${infoSpace} ${intl.formatMessage({id:messages.health_detail_child})}`,
        },
        {
            accessorKey: 'allergy',
            header: `${infoSpace} ${intl.formatMessage({id:messages.allergies})}`,
        },
        {
            accessorKey: 'childProfilForm',
            header: `${infoSpace} ${intl.formatMessage({id: messages.schooling_profile})}`,
            size: 120,
        },
        {
            accessorKey: 'childnoteForm',
            header: `${infoSpace} ${intl.formatMessage({id: messages.note})}`,
        },

    ];
} ;


export const columnsChildPlugin = (props:any) => {
    const {intl, nomSpace, options, prefixKey} = props ;
    const infoSpace = nomSpace ?? ``;
    const activeLang = intl.locale;
    const prefix = prefixKey ?? '';
    return [
        // @ts-ignore
        {
            accessorKey: `${prefix}childphotoForm`,
            header:  `${infoSpace} ${intl.formatMessage({id: messages.table_h_photo})}`,
            enableColumnFilter:false,
            grow: false,
            size: 90,
            muiTableBodyCellProps: {
                align: 'center',
            },
            // @ts-ignore
            Cell: ({ cell }) => (
                <div style={{textAlign:'center', width:'80'}}>
                    <Avatar
                        // @ts-ignore
                        src={`${cell.getValue<string>()}`}
                        sx={{ textAlign:'center'}} />
                </div>
            ),
        },
        {
            accessorKey: `${prefix}childNomPrenomForm`,
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_fullname})}`,
        },
        {
            accessorKey: `${prefix}childnomForm`,
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_child_lastname})}`,
        },
        {
            accessorKey: `${prefix}childprenomForm`,
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_child_firstname})}`,
        },
        {
            accessorKey: `${prefix}childdatenaissanceForm`,
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_child_birthday})}`,
            size: 100,
        },
        { //child.sexeForm = lang === 'en' ? GENDER_EN[0].name : GENDER_FR[0].name;
            accessorKey: `${prefix}childsexeForm`,
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_child_gender})}`,
            size: 80,
            grow: false,
            // @ts-ignore
            Cell: ({ cell }) =>  {
                // @ts-ignore
                const value = cell.getValue<string>()
                if(value === 'HOMME'){
                    return activeLang === 'en' ? 'Boy' : 'Garçon' ;
                }
                return activeLang === 'en' ? 'Girl' : 'Fille' ;
            },
        },
        {
            accessorKey: `${prefix}classeNameForm`,
            header: `${infoSpace} ${intl.formatMessage({id: messages.classroom_child})}`,
            size: 120,
        },
        {
            accessorKey: `${prefix}childpaysForm`,
            header: `${infoSpace} ${intl.formatMessage({id: messages.country})}`,
            size: 120,
            muiTableBodyCellProps: {
                align: 'center', //change head cell props
            },
            // @ts-ignore
            Cell: ({ cell }) => {
                // @ts-ignore
                const value = cell.getValue<any>()
                return (
                    <Box component="span" style={{color: stringToColor(value)}}>
                        {value}
                    </Box>
                );
            },
        },
        {
            accessorKey: `${prefix}childemergencyNom1Form`,
            header: `${infoSpace} ${intl.formatMessage({id:messages.emergency_contact_child})} 1`,
        },
        {
            accessorKey: `${prefix}childemergencyNom2Form`,
            header: `${infoSpace} ${intl.formatMessage({id:messages.emergency_contact_child})} 2`,
        },
        {
            accessorKey: `${prefix}foodRestriction`,
            header: `${infoSpace} ${intl.formatMessage({id:messages.food_restriction_child})}`,
        },
        {
            accessorKey: `${prefix}healthDetail`,
            header: `${infoSpace} ${intl.formatMessage({id:messages.health_detail_child})}`,
        },
        {
            accessorKey: `${prefix}allergy`,
            header: `${infoSpace} ${intl.formatMessage({id:messages.allergies})}`,
        },
        {
            accessorKey: `${prefix}childProfilForm`,
            header: `${infoSpace} ${intl.formatMessage({id: messages.schooling_profile})}`,
            size: 120,
        },
        {
            accessorKey: `${prefix}childnoteForm`,
            header: `${infoSpace} ${intl.formatMessage({id: messages.note})}`,
        },

    ];
} ;


export const columnsChildPlugin2 = (props:any) => {
    const {intl, nomSpace, options} = props ;
    const infoSpace = nomSpace ?? ``;
    const activeLang = intl.locale;
    return [
        // @ts-ignore
        {
            accessorKey: `childForm_childphotoForm`,
            header:  `${infoSpace} ${intl.formatMessage({id: messages.table_h_photo})}`,
            enableColumnFilter:false,
            grow: false,
            size: 90,
            muiTableBodyCellProps: {
                align: 'center',
            },
            // @ts-ignore
            Cell: ({ cell }) => (
                <div style={{textAlign:'center', width:'80'}}>
                    <Avatar
                        // @ts-ignore
                        src={`${cell.getValue<string>()}`}
                        sx={{ textAlign:'center'}} />
                </div>
            ),
        },
        {
            accessorKey: `childForm_childNomPrenomForm`,
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_fullname})}`,
        },
        {
            accessorKey: `childForm_childnomForm`,
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_child_lastname})}`,
        },
        {
            accessorKey: `childForm_childprenomForm`,
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_child_firstname})}`,
        },
        {
            accessorKey: `childForm_childdatenaissanceForm`,
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_child_birthday})}`,
            size: 100,
        },
        { //child.sexeForm = lang === 'en' ? GENDER_EN[0].name : GENDER_FR[0].name;
            accessorKey: `childForm_childsexeForm`,
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_child_gender})}`,
            size: 80,
            grow: false,
            // @ts-ignore
            Cell: ({ cell }) =>  {
                // @ts-ignore
                const value = cell.getValue<string>()
                if(value === 'HOMME'){
                    return activeLang === 'en' ? 'Boy' : 'Garçon' ;
                }
                return activeLang === 'en' ? 'Girl' : 'Fille' ;
            },
        },
        {
            accessorKey: `childForm_classeNameForm`,
            header: `${infoSpace} ${intl.formatMessage({id: messages.classroom_child})}`,
            size: 120,
        },
        {
            accessorKey: `childForm_childpaysForm`,
            header: `${infoSpace} ${intl.formatMessage({id: messages.country})}`,
            size: 120,
            muiTableBodyCellProps: {
                align: 'center', //change head cell props
            },
            // @ts-ignore
            Cell: ({ cell }) => {
                // @ts-ignore
                const value = cell.getValue<any>()
                return (
                    <Box component="span" style={{color: stringToColor(value)}}>
                        {value}
                    </Box>
                );
            },
        },
        {
            accessorKey: `childForm_childemergencyNom1Form`,
            header: `${infoSpace} ${intl.formatMessage({id:messages.emergency_contact_child})} 1`,
        },
        {
            accessorKey: `childForm_childemergencyNom2Form`,
            header: `${infoSpace} ${intl.formatMessage({id:messages.emergency_contact_child})} 2`,
        },
        {
            accessorKey: `childForm_foodRestriction`,
            header: `${infoSpace} ${intl.formatMessage({id:messages.food_restriction_child})}`,
        },
        {
            accessorKey: `childForm_healthDetail`,
            header: `${infoSpace} ${intl.formatMessage({id:messages.health_detail_child})}`,
        },
        {
            accessorKey: `childForm_allergy`,
            header: `${infoSpace} ${intl.formatMessage({id:messages.allergies})}`,
        },
        {
            accessorKey: `childForm_childProfilForm`,
            header: `${infoSpace} ${intl.formatMessage({id: messages.schooling_profile})}`,
            size: 120,
        },
        {
            accessorKey: `childForm_childnoteForm`,
            header: `${infoSpace} ${intl.formatMessage({id: messages.note})}`,
        },

    ];
} ;

export type Child = yup.InferType<typeof childSchema>;
