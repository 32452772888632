import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
    MaterialReactTable,
    MRT_ColumnDef, MRT_Localization,
    MRT_Row,
    useMaterialReactTable
} from "material-react-table";
import {MRT_Localization_EN} from 'material-react-table/locales/en/index.esm.js';
import {MRT_Localization_FR} from 'material-react-table/locales/fr/index.esm.js';
import {Avatar, CircularProgress} from "@mui/material";
import {FormattedMessage, useIntl} from "react-intl";
import messages from "../messages/general/messages";
import {makeStyles} from "@mui/styles";
import styles from "../config/config-table-jss";
import {BASEURL} from "../../config/AppUrl";
import ChildrenService from "../../service/ChildrenService";
import {useNavigate} from "react-router-dom";
import {request} from "../../helpers/axios_helpers";
import {withSnackbar} from "../../components/ui/snackbar/SnackbarHOC";
import _ from "lodash";
import {isPositiveValue} from "../../helpers/helpers";
import {useDispatch, useSelector} from "react-redux";
import {pageFormImpl, setPageForm} from "../../features/page/pageSlice";
import {getErrorMessage} from "../../helpers/errorManager";
import {useMyMaterialReactTable} from "../../components/ui/materialReactTable/MyMaterialReactTableEvents";
import {
    getMyReactTableDefaultOptions,
    MyRenderRowActions,
    MyRenderTopToolbarActions
} from "../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles((theme:any) => {
    return {
        authorizationPersonItem: {
            marginBottom: 30,
            '& .css-ov5o72-MuiTypography-root': {
                fontWeight: 'normal',
            },
            '& .MuiOutlinedInput-input': {
                fontWeight: 'normal',
                fontSize: '0.97rem',
            },
            '& .css-38f7aq-MuiInputBase-root-MuiOutlinedInput-root': {
                paddingLeft: 0,
                paddingRight: 0,
            }
        },
    }
});

const excelIcon = BASEURL+'/images/xls.png';
const pdfIcon = BASEURL+'/images/pdf.png';

function Student(props:any) {
    const {theme, locale, snackbarShowMessage} = props;
    const classes = { ...useStyles(), ...useStyles2() };
    const activeLang = locale.split('-')[0];
    const intl = useIntl() ;
    const export_xls = activeLang === 'en' ? 'Export to excel' : 'Exporter en excel';
    const [childrenDataList, setChildrenDataList] = useState<any>([]);
    const [tableDataLoading, setTableDataLoading] = useState(true);
    const [tableKeyValue, setTableKeyValue] = useState(new Date().getTime());
    const [initialized, setInitialized] = useState(false);
    const childrenService = ChildrenService();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const pageForm = useSelector((state:any) => state.page.pageForm);
    const userRef = React.useRef<any>(useSelector((state:any) => state.user.user));

    const mytableState = useMyMaterialReactTable({tableId:'Student',  updateId:1}) ;

    const logicMRT = useMemo<any>(() => getMyReactTableDefaultOptions<any>(mytableState), []);

    const tableConfigRef = useRef<any>({getState:()=>{}});


    const handleRowsDelete = (rows:any) => {
        const dataSelected:any = [];
        const dataPersonalSelectedId:any = [];
        rows.map((row:any) => {
            const data = childrenDataList[row.index];
            dataSelected.push(data);
            dataPersonalSelectedId.push(data.id);
        });

        request('DELETE', '/corebase/enfants/bouquet', dataPersonalSelectedId).then((response) => {
            const childList = childrenDataList.filter((child:any) => {
                if(!dataSelected.includes(child)){
                    return child;
                }
            });
            setChildrenDataList([...childList]);
            setTableKeyValue(new Date().getTime());
            snackbarShowMessage(<FormattedMessage id={messages.sb_delete_success} />);
        }).catch((error) => {
            // Error message
            console.log(error);
            snackbarShowMessage(<FormattedMessage id={messages.sb_error} />, 'error');
        });
    }

    const addNewChild = () => {
        navigate('/app/child/add');
    }

    const editChild = (row:MRT_Row<any>) => {
        const data:any = childrenDataList[row.index];
        showChild(data);
    }

    const showChild = (data:any) => {
        navigate('/app/child/edit', {
            state:{ data },
        });
    }

    const excelDataMapping = (rows:any) => {
        return _.map(rows, (row:any) => {
            let obj:any = {};
            const data = row.original ;
            obj[intl.formatMessage({id:messages.table_h_child_firstname})] = data.person.nom;
            obj[intl.formatMessage({id:messages.table_h_child_lastname})] = data.person.prenom;
            obj[intl.formatMessage({id:messages.table_h_child_birthday})] = data.birthDay;
            obj[intl.formatMessage({id:messages.table_h_child_gender})] = data.sexe;
            obj[intl.formatMessage({id:messages.table_h_child_classroom})] = data.classeName;
            return obj;
        });
    }

    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: 'person.photoDataTable',
                header: intl.formatMessage({id: messages.table_h_child_avatar}),
                size: 70,
                Cell: ({ cell }) => (
                    <div style={{textAlign:'center', width:'110px'}}>
                        <Avatar src={cell.getValue<string>()} sx={{ textAlign:'center'}} />
                    </div>
                ),
            },
            {
                accessorKey: 'person.nom',
                header: intl.formatMessage({id: messages.table_h_child_lastname}),
            },
            {
                accessorKey: 'person.prenom',
                header: intl.formatMessage({id: messages.table_h_child_firstname}),
            },
            {
                accessorKey: 'person.birthDay',
                header: intl.formatMessage({id: messages.table_h_child_birthday}),
            },
            { //child.sexeForm = lang === 'en' ? GENDER_EN[0].name : GENDER_FR[0].name;
                accessorKey: 'person.sexe',
                header: intl.formatMessage({id: messages.table_h_child_gender}),
                Cell: ({ cell }) =>  {
                    const value = cell.getValue<string>();
                    if(value === 'HOMME'){
                        return activeLang === 'en' ? 'Boy' : 'Garçon' ;
                    }
                    return activeLang === 'en' ? 'Girl' : 'Fille' ;
                },
            },
            {
                accessorKey: 'person.classeName',
                header: intl.formatMessage({id: messages.table_h_child_classroom}),
            },
        ],
        [activeLang],
    );
    const localization = useMemo<MRT_Localization>(() => activeLang === 'en' ? MRT_Localization_EN : MRT_Localization_FR, [activeLang]) ;
    tableConfigRef.current = useMaterialReactTable({
        ...logicMRT.defaultOptions,
        localization,
        columns,
        data: childrenDataList,
        state:{
            showProgressBars: tableDataLoading,
            showSkeletons: tableDataLoading,
        },
        renderTopToolbarCustomActions: ({ table }: {table:any}) => (
            <MyRenderTopToolbarActions
                table={table}
                addNewDataTable={addNewChild}
                handleRowsDelete={() => handleRowsDelete(table.getSelectedRowModel().rows)}
                deleteDisabled={_.isEmpty(table.getSelectedRowModel().rows)}
                styleClasses={classes}
                export_xls_title={export_xls}
                xls_nom_fichier="Liste_Enfants"
                excelDataMapping={excelDataMapping(table.getPrePaginationRowModel().rows)}
            />
        ),
        renderRowActions: ({row, table}: {row:any, table:any}) => (
            <MyRenderRowActions
                editRow={() => editChild(row)}
                styleClasses={classes}
            />
        )
    });

    useEffect(() => {
        if(pageForm.reload){
            (async () => {
                try {
                    const childListRequest = await childrenService.getAllChildrenData();
                    let childrenList:any = childListRequest._embedded !== undefined ? childListRequest._embedded?.enfantDTOModelList : [];
                    const dataListFormatted = childrenService.reformatChildrenList(childrenList);
                    setChildrenDataList([...dataListFormatted]);
                    setTableDataLoading(false);

                    if(_.isObject(pageForm)){
                        // @ts-ignore
                        if(pageForm.cle === 'children_list' && isPositiveValue(pageForm.id) ){
                            // @ts-ignore
                            const dataId = pageForm.id ;
                            dispatch(setPageForm(_.cloneDeep(pageFormImpl)));
                            // @ts-ignore
                            request("GET",  `/corebase/enfants/${dataId}`, {}).then((response:any) => {

                                if(isPositiveValue(response.data.id)){
                                    const dataToShow: any[] = [response.data];
                                    const dataToShowFrontEnd = childrenService.reformatChildrenList(dataToShow);
                                    showChild(dataToShowFrontEnd[0]);
                                }
                                setInitialized(true);
                            }).catch((error:any) => {
                                setInitialized(true);
                            });
                        }else{
                            setInitialized(true);
                        }
                    }else{
                        setInitialized(true);
                    }

                }
                catch (error) {
                    snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                }
            })()
        }
    }, [pageForm.reload]);

    useEffect(() => {
        (async () => {
            try {
                const childListRequest = await childrenService.getAllChildrenData();
                let childrenList:any = childListRequest._embedded !== undefined ? childListRequest._embedded?.enfantDTOModelList : [];
                const dataListFormatted = childrenService.reformatChildrenList(childrenList);
                setChildrenDataList([...dataListFormatted]);
                setTableDataLoading(false);

                if(_.isObject(pageForm)){
                    // @ts-ignore
                    if(pageForm.cle === 'children_list' && isPositiveValue(pageForm.id) ){
                        // @ts-ignore
                        const dataId = pageForm.id ;
                        dispatch(setPageForm(_.cloneDeep(pageFormImpl)));
                        // @ts-ignore
                        request("GET",  `/corebase/enfants/${dataId}`, {}).then((response:any) => {

                            if(isPositiveValue(response.data.id)){
                                const dataToShow: any[] = [response.data];
                                const dataToShowFrontEnd = childrenService.reformatChildrenList(dataToShow);
                                showChild(dataToShowFrontEnd[0]);
                            }
                            setInitialized(true);
                        }).catch((error:any) => {
                            setInitialized(true);
                        });
                    }else{
                        setInitialized(true);
                    }
                }else{
                    setInitialized(true);
                }

            }
            catch (error) {
                snackbarShowMessage(getErrorMessage(error)?.message, 'error');
            }
        })();

        return () => logicMRT.storeState(tableConfigRef.current.getState());
    }, []);

    if(!initialized){
        return(
            <div style={{width:'100%', textAlign:'center', padding:20}}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <div className={classes.root}>
            <MaterialReactTable
                table={tableConfigRef.current}
                key={tableKeyValue}
            />
            {/*
            <MaterialReactTable
                initialState={{ pagination: { pageSize: 15, pageIndex: 0 } }}
                columns={columns}
                data={childrenDataList}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                enableHiding={false}
                enableColumnResizing={true}
                state={{
                    density:'compact',
                    isLoading: tableDataLoading,
                }}
                localization={activeLang === 'en' ? MRT_Localization_EN : MRT_Localization_FR}
                enableEditing
                enableRowSelection
                positionActionsColumn="last"
                key={tableKeyValue}
                icons={{
                    SaveIcon: (props:any) => (<DoneIcon {...props} color="primary" sx={{ marginLeft:-2, }} />),
                    CancelIcon: (props:any) => (<CloseIcon color="primary" {...props} />),
                }}
                muiTableContainerProps={{ sx: {maxHeight:'500px'} }}
                renderTopToolbarCustomActions={({ table }: {table:any}) => (
                    <Box
                        className={classes.topToolbarActionsButton}
                        sx={{ display: 'flex',  flexWrap: 'wrap' }}
                    >
                        {
                            canShowButton(userRef.current, 'enfants', [STATUS.ADD]) &&
                            <Button variant={"contained"}
                                    component="label"
                                    size="small"
                                    color="primary"
                                    sx={{ textTransform: 'none', marginLeft:1, paddingLeft:1, paddingRight:2}}
                                    onClick={() => addNewChild()}
                            >
                                <Icon sx={{marginRight:1}}>add</Icon>
                                <FormattedMessage id={messages.add_action} />
                            </Button>
                        }
                        {
                            canShowButton(userRef.current, 'enfants', [STATUS.DEL]) &&
                            <Button variant={"contained"}
                                    component="label"
                                    size="small"
                                    color="error"
                                    sx={{ textTransform: 'none', marginLeft:2, paddingLeft:1, paddingRight:2}}
                                    disabled={_.isEmpty(table.getSelectedRowModel().rows)}
                                    onClick={() => handleRowsDelete(table.getSelectedRowModel().rows)}
                            >
                                <Icon sx={{marginRight:1}}>delete</Icon>
                                <FormattedMessage id={messages.delete_action} />
                            </Button>
                        }
                    </Box>
                )}

                renderRowActions={({ row, table }) => (
                    <Box sx={{ display: 'flex', gap: '0.3rem' }}>
                        <Tooltip arrow placement="left" title={<FormattedMessage id={messages.edit} />}>
                            <IconButton
                                onClick={() => editChild(row)}
                                sx={{ color: 'rgba(0, 0, 0, 0.3)'}}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
            /> */}
        </div>
    );
}

export default withSnackbar(Student);
