import {Common, commonDataImp} from "./Common";
import _ from "lodash";
import * as yup from "yup";
import {isColumnVisible} from "../../helpers/helpers";
import messages from "../../page/messages/general/messages";


export const SCOLARITEANNEE_CONSTANT = {
    convertToFrontEnd: (logic:any, item:ScolariteAnnee) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(scolariteAnneeDataImpl);
        return {...itemInit, ...item} ;
    },
    convertToBackEnd: (logic:any, item:ScolariteAnnee) => item,
};

export  const scolariteAnneeDataImpl: ScolariteAnnee = {
    id: null,
    nom: '',
    dateDebut: 0,
    dateFin: 0,
    code: '',
    migrateNextYear: false,
    common: _.cloneDeep(commonDataImp)
}

export const scolariteAnneeSchema = yup.object().shape({
    id: yup.number().nullable(),
    nom: yup.string().nullable(),
    dateDebut: yup.number().nullable(),
    dateFin: yup.number().nullable(),
    code: yup.string().nullable(),
    migrateNextYear: yup.boolean().nullable(),
    common: yup.mixed<Common>(),
});


export const excelScolariteAnneeDataMapping = ({rows=[], columns=[], intl={}, table=[]}:{ rows:any[]; intl:any;  table: any; columns: any[];}) => {
    return _.map(rows, (row:any) => {
        let obj:any = {};
        const data = row.original ;
        if(isColumnVisible(columns, 'code')){
            obj[intl.formatMessage({id:messages.header_code})] = data.code;
        }
        if(isColumnVisible(columns, 'nom')){
            obj[intl.formatMessage({id:messages.table_h_nom})] = data.nom;
        }
        return obj;
    });
}

export const excelScolariteAnneeDataMappingPlugin = ({obj={}, row={}, intl={}, infoSpace=``, columns=[], table={}, prefix=``}:{obj:any; row:any; intl:any; infoSpace:string; table: any; columns: any[]; prefix: string;}) => {
    const data = row?.groupesectionForm ;
    if(_.isObject(data) === false){
        return obj ;
    }

    if(isColumnVisible(columns, `${prefix}nom`)){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.table_h_nom})}`] = data.nom;
    }
    if(isColumnVisible(columns, `${prefix}code`)){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.header_code})}`] = data.code;
    }
    return obj;
}

export const scolariteAnneeCustomOptionsPlugin = {
    columnVisibility : {
        'scolariteAnneeForm.nom': true,
        'scolariteAnneeForm.code': false,
    }
} ;


export const scolariteAnneeCustomOptionsPlugin2 = {
    columnVisibility : {
        'scolariteAnneeForm_nom': true,
        'scolariteAnneeForm_code': false,
    }
} ;

export const columnsScolariteAnnee = (props:any) => {
    const {intl, nomSpace, options} = props ;
    const infoSpace = nomSpace ?? ``;

    return [
        {
            accessorKey: 'nom',
            header: intl.formatMessage({id: messages.table_h_nom}),
        },
        {
            accessorKey: 'code',
            header: intl.formatMessage({id: messages.header_code}),
        }
    ];
} ;


export const columnsScolariteAnneePlugin = (props:any) => {
    const {intl, nomSpace, options, prefixKey} = props ;
    const infoSpace = nomSpace ?? ``;
    const prefix = prefixKey ?? '';

    return [
        {
            accessorKey: `${prefix}nom`,
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_nom})}`,
            size: options?.nomSize ?? undefined,
            filterVariant: 'multi-select',
        },
        {
            accessorKey: `${prefix}code`,
            header: `${infoSpace} ${intl.formatMessage({id: messages.header_code})}`,
            size: options?.codeSize ?? undefined,
        }
    ];
}

export const columnsScolariteAnneePlugin2 = (props:any) => {
    const {intl, nomSpace, options} = props ;
    const infoSpace = nomSpace ?? ``;

    return [
        {
            accessorKey: `scolariteAnneeForm_nom`,
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_nom})}`,
            size: options?.nomSize ?? undefined,
            filterVariant: 'multi-select',
        },
        {
            accessorKey: `scolariteAnneeForm_code`,
            header: `${infoSpace} ${intl.formatMessage({id: messages.header_code})}`,
            size: options?.codeSize ?? undefined,
        }
    ];
}
export type ScolariteAnnee = yup.InferType<typeof scolariteAnneeSchema>;
