import React, {SyntheticEvent, useEffect, useRef} from 'react';
import SchoolingSubMenu from "../SchoolingSubMenu";
import Box from "@mui/material/Box";
import {useForm} from "react-hook-form";
import _ from "lodash";
import {yupResolver} from "@hookform/resolvers/yup";
import scolariteStyles from "../jss/scolarite-jss";
import {momentLocalizer} from "react-big-calendar";
import moment from "moment/moment";
import {useDispatch} from "react-redux";
import ScolariteEcheancierEnfantEditForm from "./ScolariteEcheancierEnfantEditForm";
import ScolariteEcheancierEnfantList from './ScolariteEcheancierEnfantList';
import ScolariteEcheancierEnfantDeleteForm from "./ScolariteEcheancierEnfantDeleteForm";
import useScolariteEcheancierEnfantVariables from './businesslogic/ScolariteEcheancierEnfantVariables';
import scolariteEcheancierEnfantBusinessLogic from "./businesslogic/ScolariteEcheancierEnfantBusinessLogic";
import {withSnackbar} from "../../../components/ui/snackbar/SnackbarHOC";
import {Child, childDataImpl} from "../../../constants/type/Child";
import {
    Autocomplete,
    Avatar,
    Checkbox,
    FormControl,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {FormattedMessage} from "react-intl";
import messages from "../../messages/general/messages";
import classNames from "classnames";
import Chip from "@mui/material/Chip";
import scolariteEcheancierEnfantStateUpdate from "./businesslogic/ScolariteEcheancierEnfantStateUpdate";
import {
    scolariteEcheancierEnfantDataImpl,
    scolariteEcheancierEnfantSchema
} from "../../../constants/type/ScolariteEcheancierEnfant";
import {isPositiveValue} from "../../../helpers/helpers";
import ScolariteEcheancierEnfantGenerateForm from "./ScolariteEcheancierEnfantGenerateForm";
import {GroupEnfant} from "../../../constants/type/GroupEnfant";
import {ScolariteGroup} from "../../../constants/type/ScolariteGroup";

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const useBusinessLogic = (props: any): any => {
    const styleClasses = scolariteStyles();
    const formRef = useRef<any>(useForm({
        defaultValues:_.cloneDeep(scolariteEcheancierEnfantDataImpl),
        // @ts-ignore
        resolver: yupResolver(scolariteEcheancierEnfantSchema),
    }));


    return {
        theme: props.theme,
        styleClasses: styleClasses,
        formRef,
        ...useScolariteEcheancierEnfantVariables({styleClasses, ...props})
    }
};
export const ScolariteEcheancierEnfantContext = React.createContext({});
function ScolariteEcheancierEnfant(props:any) {
    const logic = useBusinessLogic(props);

    const styleClasses = logic.styleClasses;

    const menuLeaveTimerRef = useRef<any>();
    const searchPlaceholderRef =  useRef<string>(logic.activeLang === 'en' ? 'Find a child' : 'Rechercher un enfant');

    const setChild = (item:Child | null) => {
        if(_.isObject(item) === false){
            item = _.cloneDeep(childDataImpl);
        }
        let data = _.cloneDeep(logic.dataSel);
        data.enfantId = item?.id ?? 0;
        // @ts-ignore
        data.classeId = item?.eleves?.[0]?.classe.id ?? 0 ;
        scolariteEcheancierEnfantStateUpdate.dataSel(logic, data);
        scolariteEcheancierEnfantStateUpdate.childSelected(logic, item);
        scolariteEcheancierEnfantStateUpdate.numeriChange(logic, moment().valueOf());
    }

    const handleOnScolariteAnneChange = (event: any) => {
        scolariteEcheancierEnfantStateUpdate.idScolariteAnneeSel(logic, parseInt(event.target.value));
    };
    const handleScolariteGroup = (event: any) => {
        const ids : number[]= [] ;
        event.target.value.forEach((id:string) => {
            ids.push(parseInt(id));
        });
        scolariteEcheancierEnfantStateUpdate.scolariteGroupIdListSel(logic, ids);
        scolariteEcheancierEnfantStateUpdate.numeriChange(logic, moment().valueOf());
    };

    const handleChildChange = (event: SyntheticEvent, value: Child) => setChild(value);

    useEffect(() => {
        clearTimeout(menuLeaveTimerRef.current);

        if(!isPositiveValue(logic.childSelected.id) || _.isEmpty(logic.scolariteGroupIdListSel)){
            scolariteEcheancierEnfantStateUpdate.dataList(logic, []) ;
            scolariteEcheancierEnfantStateUpdate.tableKeyValue(logic, new Date().getTime());
            return ;
        }

        menuLeaveTimerRef.current = setTimeout(function() {
            scolariteEcheancierEnfantStateUpdate.tableDataLoading(logic, true);
            scolariteEcheancierEnfantBusinessLogic.loadScolariteEcheanciers(logic, logic.childSelected.id, logic.scolariteGroupIdListSel).then((val:any) => {
                scolariteEcheancierEnfantStateUpdate.tableDataLoading(logic, false);
            }).catch((error:any) => {
                console.error(error);
                scolariteEcheancierEnfantStateUpdate.tableDataLoading(logic, false);
            });
        }, 1500);
        return () => {};
    }, [logic.numeriChange]);

    const filtrerScolariteGroupOfAnnee = (idScolariteAnneeSel:number, idChild:number, profilsIdList: number[]) => {
        const groupEnfant : GroupEnfant =  _.find(logic.groupEnfantsRef.current, {'enfantId':idChild, 'scolariteAnneeId':idScolariteAnneeSel});
        if(!_.isObject(groupEnfant)){
            return ;
        }
        const groupesectionId = groupEnfant.groupesectionId ;
       const scolariteGroups : ScolariteGroup[] =  _.filter(logic.scolariteGroupsRef.current, item => {
           const isMatchProfil = !isPositiveValue(item.profilsId) || _.includes(profilsIdList, item.profilsId);
           return isMatchProfil && item.scolariteAnneeId === idScolariteAnneeSel && item.groupesectionId === groupesectionId ;
       });

        const idscolgrouplist = _.map(scolariteGroups, item => item.id) ;
        const idScolGroupSels = _.intersection(idscolgrouplist, logic.scolariteGroupIdListSel);
        scolariteEcheancierEnfantStateUpdate.groupEnfantSel(logic, groupEnfant);
        scolariteEcheancierEnfantStateUpdate.scolariteGroupList(logic, scolariteGroups);
        scolariteEcheancierEnfantStateUpdate.scolariteGroupIdListSel(logic, idScolGroupSels);
    }

    useEffect(() => {
        if(isPositiveValue(logic.idScolariteAnneeSel) && isPositiveValue(logic.childSelected?.id)){
            const profilsIds: number[] = _.map(logic.childSelected?.enfantProfils, item => item.profilsId);
            filtrerScolariteGroupOfAnnee(logic.idScolariteAnneeSel, logic.childSelected.id, profilsIds);
        }
    }, [logic.childSelected, logic.idScolariteAnneeSel]);


    useEffect(() => {
        scolariteEcheancierEnfantStateUpdate.sumEcheancier(logic);
    }, [logic.dataList]);

    useEffect(() => {
        moment.locale(logic.activeLang);
        scolariteEcheancierEnfantBusinessLogic.initPostConstruct(logic);

        return () => logic.storeState(logic.tableConfigRef.current.getState());
    }, []);

    return (
        <div className={styleClasses.root}>
            <Box className={styleClasses.buttonContainer}>
                <SchoolingSubMenu classes={styleClasses} selected="scolaritecheancierenfant"/>
            </Box>
            <Box className={styleClasses.searchBox}>
                <Grid container spacing={2}>
                    <Grid item md={4} xs={12}>
                        <FormControl
                            sx={{
                                display: { xs: 'block', sm: 'inline-block'},
                                marginBottom: { xs: 2},
                                marginRight: { sm: 2 },
                                mt:2,
                                width:'100%',
                            }}
                        >
                            <Autocomplete
                                sx={{
                                    width: {
                                        xs: '100%',
                                        sm: '100%',
                                        fontWeight: 'normal',
                                    }
                                }}
                                options={logic.childrenRef.current}
                                value={logic.childSelected}
                                autoHighlight
                                getOptionLabel={(opt:any) => opt.childNomPrenomForm}
                                isOptionEqualToValue={(opt:any, value:any) => opt.id === value.id}
                                loading={false}
                                defaultValue="0"
                                onChange={handleChildChange}
                                renderOption={(props, opt:any) => (
                                    <Box  component="li" sx={{'& > img': {mr: 2, flexShrink: 0}, fontWeight: 400}} {...props}>
                                        <Avatar alt="avatar"  src={opt.childphotoForm} sx={{mr: 2}}/>
                                        {opt.childNomPrenomForm}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={<FormattedMessage id={messages.choose_child} />}
                                        required
                                        color="secondary"
                                        variant="outlined"
                                        sx={{marginTop: 0.4, width: '100% !important'}}
                                        size="small"
                                        placeholder={searchPlaceholderRef.current}
                                        inputProps={{
                                            ...params.inputProps
                                        }}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item md={3} xs={12}>
                        <FormControl
                            sx={{
                                display: { xs: 'block', sm: 'inline-block'},
                                marginBottom: { xs: 2},
                                marginRight: { sm: 2 },
                                mt:2,
                                width:'100%',
                            }}
                        >
                            <TextField
                                select
                                label={<FormattedMessage id={messages.scolaritee_annee}/>}
                                sx={{
                                    width: {
                                        xs: '100%',
                                        sm: '100%',
                                        fontWeight: 'normal',
                                    }
                                }}
                                color={"secondary"}
                                value={logic.idScolariteAnneeSel}
                                onChange={handleOnScolariteAnneChange}
                                inputProps={{
                                    required: "Required field",
                                    min: 1,
                                }}
                            >
                                <MenuItem key="0" value="0" sx={{fontWeight: 400}}>-------</MenuItem>
                                {logic.scolariteAnneesRef.current?.map((opt: any) => (
                                    <MenuItem key={opt.id} value={opt.id} sx={{fontWeight: 400}}>
                                        {opt.nom}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormControl>

                    </Grid>
                    <Grid item md={5} xs={12}>
                        <div className={classNames(styleClasses.inputField, styleClasses.inputFieldMarginBottom)}>
                            <InputLabel id="demo-sft-label"><FormattedMessage id={messages.schooling_fees_title} /></InputLabel>
                            <Select
                                labelId="demo-sft-label"
                                id="demo-sft"
                                multiple
                                size="small"
                                sx={{ width: { xs: '100% !important'}, }}
                                color={"secondary"}
                                value={logic.scolariteGroupIdListSel}
                                onChange={handleScolariteGroup}
                                input={<OutlinedInput label={<FormattedMessage id={messages.schooling_fees_title}/>} />}
                                renderValue={(selected:any) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value:any) => (
                                            <Chip key={value} label={logic.scolariteGroupsMapRef.current.get(value)?.nom ?? ``} />
                                        ))}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                                disabled={!isPositiveValue(logic.childSelected?.id)}
                            >
                                <MenuItem key="" value="" sx={{fontWeight:400}}>------</MenuItem>
                                {logic.scolariteGroupList?.map((opt:any) => (
                                    <MenuItem key={opt.id} value={opt.id} sx={{fontWeight:400}}>
                                        <Checkbox checked={_.includes(logic.scolariteGroupIdListSel, opt.id)} />
                                        <ListItemText primary={`${opt.nom} (${opt.scolariteTypeForm?.nom})`} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    </Grid>

                </Grid>

                <Box sx={{display: logic.childSelected.id > 0 ? 'block' : 'none' }}>
                    <Grid container spacing={2}>
                        <Grid item md={4} xs={12}>
                            <Box className={styleClasses.childDetails}>
                                <Box className={classNames(styleClasses.childInfoBox)}>
                                    <Avatar className={styleClasses.customAvatar}
                                            src={logic.childSelected.childphotoForm}
                                            sx={{width:56, height:56}}
                                            alt={logic.childSelected.childNomPrenomForm}
                                    />
                                    <Box className={styleClasses.customTitle} letterSpacing={1} sx={{ml:1}}>
                                        <Box>
                                            <Typography className={styleClasses.titleChildInfo} variant="subtitle1">
                                                {logic.childSelected.childNomPrenomForm}
                                            </Typography>
                                            <span style={{textTransform: "capitalize"}}>{logic.groupEnfantSel?.groupesectionForm?.nom}</span>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item md={8} xs={12}>
                            <Box className={styleClasses.amountDetails}>
                                <Grid container spacing={2}>
                                    <Grid item md={12} xs={12}>
                                        <Typography
                                            variant={"subtitle2"}
                                            color={"green"}
                                            sx={{ textAlign:'center' }}
                                        >
                                            TOTAL : {new Intl.NumberFormat("fr-FR").format(logic.totalMontantEcheancier ?? 0)} FCFA
                                        </Typography>
                                    </Grid>

                                </Grid>
                            </Box>
                        </Grid>

                        <Grid item md={5} xs={12}> </Grid>

                    </Grid>
                </Box>

            </Box>

            <ScolariteEcheancierEnfantContext.Provider value={logic}>
                <ScolariteEcheancierEnfantList />
            </ScolariteEcheancierEnfantContext.Provider>
            <ScolariteEcheancierEnfantContext.Provider value={logic}>
                <ScolariteEcheancierEnfantEditForm />
            </ScolariteEcheancierEnfantContext.Provider>
            <ScolariteEcheancierEnfantContext.Provider value={logic}>
                <ScolariteEcheancierEnfantDeleteForm />
            </ScolariteEcheancierEnfantContext.Provider>
            <ScolariteEcheancierEnfantContext.Provider value={logic}>
                <ScolariteEcheancierEnfantGenerateForm />
            </ScolariteEcheancierEnfantContext.Provider>
        </div>
    );
}

export default withSnackbar(ScolariteEcheancierEnfant);
