import {Common, commonDataImp} from "./Common";
import _ from "lodash";
import * as yup from "yup";
import {GroupEnfantProfils} from "./GroupEnfantProfils";
import {
    Child,
    childCustomOptionsPlugins,
    childDataImpl,
    columnsChildPlugin,
    excelChildDataMappingPlugin
} from "./Child";
import {
    columnsGroupPlugin,
    excelGroupDataMappingPlugin,
    Groupesection,
    groupeSectionCustomOptionsPlugin,
    groupesectionDataImpl
} from "./Groupesection";
import {
    columnsScolariteAnneePlugin,
    ScolariteAnnee,
    scolariteAnneeCustomOptionsPlugin,
    scolariteAnneeDataImpl
} from "./ScolariteAnnee";
import messages from "../../page/messages/general/messages";
import {isColumnVisible} from "../../helpers/helpers";


export const GROUPENFANT_CONSTANT = {
    convertToFrontEnd: (logic:any, item:GroupEnfant) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(groupEnfantDataImpl);
        const data = {...itemInit, ...item} ;
        data.groupesectionForm = _.find(logic.groupesectionsRef.current, {id:data.groupesectionId}) ?? _.cloneDeep(groupesectionDataImpl);

        data.childForm = logic.childrenMapRef.current.get(data.enfantId) ?? _.cloneDeep(childDataImpl);

        data.scolariteAnneeForm = _.find(logic.scolariteAnneesRef.current, {id:data.scolariteAnneeId}) ?? _.cloneDeep(scolariteAnneeDataImpl);
        data.scolariteAnNomForm = data.scolariteAnneeForm?.nom ?? ``;
        data.groupEnfantprofilForm   = _.reduce(data.groupEnfantProfils, function(profilNameReduce, groupEnfantProfil) {
            const profilname = _.find(logic.profilsRef.current, {id:groupEnfantProfil?.profilsId})?.nom ?? ``;
            const previousinfo = _.isEmpty(profilNameReduce) ? `` : profilNameReduce+', ';
            return `${previousinfo}${profilname}`;
        }, ``);


        return data ;
    },
    convertToBackEnd: (logic:any, item:GroupEnfant) => item,
};

export  const groupEnfantDataImpl: GroupEnfant = {
    id: null,
    enfantId: 0,
    groupesectionId: 0,
    scolariteAnneeId: 0,
    groupEnfantProfils: [],
    common: _.cloneDeep(commonDataImp),
    groupEnfantprofilForm: ``,
    scolariteAnNomForm:``,
    childForm: _.cloneDeep(childDataImpl),
    groupesectionForm:_.cloneDeep(groupesectionDataImpl),
    scolariteAnneeForm:_.cloneDeep(scolariteAnneeDataImpl),
}

export const groupEnfantSchema = yup.object().shape({
    id: yup.number().nullable(),
    enfantId: yup.number().nullable().required(),
    groupesectionId: yup.number().nullable().required(),
    scolariteAnneeId: yup.number().nullable().required(),
    groupesectionForm: yup.mixed<Groupesection>(),
    childForm: yup.mixed<Child>(),
    groupEnfantprofilForm: yup.string(),
    scolariteAnneeForm: yup.mixed<ScolariteAnnee>(),
    scolariteAnNomForm: yup.string(),
    groupEnfantProfils: yup.array().of(yup.mixed<GroupEnfantProfils>()).nonNullable(),
    common: yup.mixed<Common>()
});


export const excelGroupEnfantDataMapping = (
    {rows=[], columns=[], intl={}, infoSpace=``, childSpace=``, groupSpace=``, table={}} :
        {rows:any[]; intl:any; infoSpace: string; childSpace: string; groupSpace: string; columns: any[]; table: any;  }
) => {
    const prefix=``
    return  _.map(rows, (row:any) => {
        let obj:any = {};
        const data = row.original;
        obj = excelChildDataMappingPlugin({obj, row:data, table, intl, columns, prefix: `${prefix}childForm.`, infoSpace:intl.formatMessage({id:messages.table_h_enfant})});
        obj = excelGroupDataMappingPlugin({obj, row:data, table, intl, columns, prefix: `${prefix}groupesectionForm.`, infoSpace:intl.formatMessage({id:messages.table_h_group})});

        if(isColumnVisible(columns, 'groupEnfantprofilForm')){
            obj[`${infoSpace} ${intl.formatMessage({id:messages.table_h_profil})}`] = data.groupEnfantprofilForm;
        }
        if(isColumnVisible(columns, 'scolariteAnNomForm')){
            obj[`${infoSpace} ${intl.formatMessage({id:messages.table_h_scolarite})}`] = data.scolariteAnneeForm.nom;
        }
        return obj;
    });
}

export const excelGroupEnfantDataMappingPlugin = ({obj={}, row={}, intl={}, infoSpace=``, columns=[], table={}, prefix=``}:{obj:any; row:any; intl:any; infoSpace:string; columns: any[]; table: any; prefix: string;}) => {
    const data : GroupEnfant = row?.groupEnfantForm;
    if(_.isObject(data) === false){
        return obj ;
    }
    obj = excelChildDataMappingPlugin({obj, row:data, table, intl, columns, prefix: `${prefix}childForm.`, infoSpace:intl.formatMessage({id:messages.table_h_enfant})});
    obj = excelGroupDataMappingPlugin({obj, row:data, table, intl, columns, prefix: `${prefix}groupesectionForm.`, infoSpace:intl.formatMessage({id:messages.table_h_group})});

    if(isColumnVisible(columns, `${prefix}groupEnfantprofilForm`)){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.table_h_profil})}`] = data.groupEnfantprofilForm;
    }
    if(isColumnVisible(columns, `${prefix}scolariteAnNomForm`)){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.table_h_scolarite})}`] = data.scolariteAnneeForm?.nom;
    }
    return obj;
}

export const groupEnfantCustomOptions = {
    columnVisibility : {
        ...childCustomOptionsPlugins.columnVisibility,
        ...groupeSectionCustomOptionsPlugin.columnVisibility,
        ...scolariteAnneeCustomOptionsPlugin.columnVisibility,
        'childForm.childNomPrenomForm': true,
    }
} ;

export const groupEnfantCustomOptionsPlugin = {
    columnVisibility : {
        ...childCustomOptionsPlugins.columnVisibility,
        ...groupEnfantCustomOptions.columnVisibility,
        ...scolariteAnneeCustomOptionsPlugin.columnVisibility,
        'childForm.childNomPrenomForm':true,
    }
} ;

export const columnsGroupEnfant = (props:any) => {
    const {intl, nomSpace} = props ;
    const infoSpace = nomSpace ?? ``;
    const scolariteAnneeOptions = {
        nomSize: 100,
        codeSize: 80,
    }
    return [
        ...columnsChildPlugin({intl, prefixKey:`childForm.`, nomSpace: intl.formatMessage({id:messages.table_h_enfant})}),
        ...columnsScolariteAnneePlugin({intl, nomSpace: intl.formatMessage({id:messages.table_h_scolarite}), options:scolariteAnneeOptions, prefixKey:'scolariteAnneeForm.'}),
        ...columnsGroupPlugin({intl, prefixKey:`groupesectionForm.`, nomSpace: intl.formatMessage({id:messages.table_h_group})}),
        {
            accessorKey: 'groupEnfantprofilForm',
            header: `${infoSpace} ${intl.formatMessage({id:messages.table_h_profil})}`,
            size: 150,
        },
    ];
} ;

export const columnsGroupEnfantPlugin = (props:any) => {
    const {intl, nomSpace, options, prefixKey} = props ;
    const infoSpace = nomSpace ?? ``;
    const prefix = prefixKey ?? '';
    return [
        ...columnsChildPlugin({intl, prefixKey:`${prefix}childForm.`, nomSpace: intl.formatMessage({id:messages.table_h_enfant})}),
        ...columnsGroupPlugin({intl, prefixKey:`${prefix}groupesectionForm.`, nomSpace: intl.formatMessage({id:messages.table_h_group})}),
        {
            accessorKey: `${prefix}scolariteAnNomForm`,
            header: `${infoSpace} ${intl.formatMessage({id:messages.table_h_scolarite})}`,
            size: options?.scolariteAnNomFormSize ?? undefined,
        },
        {
            accessorKey: `${prefix}groupEnfantprofilForm`,
            header: `${infoSpace} ${intl.formatMessage({id:messages.table_h_profil})}`,
            size: options?.groupEnfantprofilFormSize ?? undefined,
            filterVariant: 'multi-select',
        },
    ];
} ;

export type GroupEnfant = yup.InferType<typeof groupEnfantSchema>;


